import React from 'react';
import epoxidharz from '../../images/article/epoxidharz_article.jpeg'

const Epoxidharz = () => {
  return (
      <div className="bg-gray-100 p-10">
        <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">
          Verarbeitung und Eigenschaft von Epoxidharz
        </h1>
        <img src={epoxidharz} alt="Article Image" className="mb-10 rounded h-[400px]" />
        <div className="container mx-auto">
          <p className="text-lg text-gray-700 mb-10">
            Epoxidharz und Holz sind zwei Materialien, die oft zusammen verwendet werden, um einzigartige und attraktive Dekorationsgegenstände zu schaffen. Epoxidharz ist ein kristallklares, widerstandsfähiges Harz, das oft für den Einsatz in industriellen Anwendungen verwendet wird. Holz hingegen ist ein natürliches Material, das warm und einladend wirkt und eine Vielzahl von Möglichkeiten für die Gestaltung bietet.
            Wenn man diese beiden Materialien miteinander kombiniert, kann man eine Vielzahl von verschiedenen Designs und Formen erzielen. Epoxidharz kann verwendet werden, um Holz zu schützen, zu füllen oder zu versiegeln, oder um ein ungewöhnliches und optisch ansprechendes Muster zu schaffen.
          </p>
        </div>

      </div>
  );
};

export default Epoxidharz;
