import React from 'react'

const ImpressumText = () => {
  return (
      <div className="container">
        <div className="container">
          <div >
            <p>Inhaber:<br/> Jürgen Kilchert<br/> Egerländer Straße
              395<br/> Warmensteinach
            </p>

            <h1>Datenschutz</h1>
            <br/>

            <p><strong>1. Begriffsbestimmungen</strong></p>

            <p>Die Datenschutzerklärung beruht auf den Begrifflichkeiten, die
              durch den
              Europäischen Richtlinien- und Verordnungsgeber beim Erlass der
              Datenschutz-Grundverordnung (DS-GVO) verwendet wurden. Unsere
              Datenschutzerklärung soll sowohl für die Öffentlichkeit als auch
              für unsere
              Kunden und Geschäftspartner einfach lesbar und verständlich sein.
              Um dies zu
              gewährleisten, möchten wir vorab die verwendeten Begrifflichkeiten
              erläutern.</p>

            <p>Wir verwenden in dieser Datenschutzerklärung unter anderem die
              folgenden
              Begriffe:</p>

            <ul>
              <li><strong>a) personenbezogene Daten</strong>Personenbezogene
                Daten sind
                alle Informationen, die sich auf eine identifizierte oder
                identifizierbare
                natürliche Person (im Folgenden „betroffene Person“) beziehen.
                Als
                identifizierbar wird eine natürliche Person angesehen, die
                direkt oder
                indirekt, insbesondere mittels Zuordnung zu einer Kennung wie
                einem Namen,
                zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung
                oder zu
                einem oder mehreren besonderen Merkmalen, die Ausdruck der
                physischen,
                physiologischen, genetischen, psychischen, wirtschaftlichen,
                kulturellen
                oder sozialen Identität dieser natürlichen Person sind,
                identifiziert
                werden kann.
              </li>
              <li><strong>b) betroffene Person</strong>Betroffene Person ist
                jede
                identifizierte oder identifizierbare natürliche Person, deren
                personenbezogene Daten von dem für die Verarbeitung
                Verantwortlichen
                verarbeitet werden.
              </li>
              <li><strong>c) Verarbeitung</strong>Verarbeitung ist jeder mit
                oder ohne
                Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede
                solche
                Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie
                das Erheben,
                das Erfassen, die Organisation, das Ordnen, die Speicherung, die
                Anpassung
                oder Veränderung, das Auslesen, das Abfragen, die Verwendung,
                die
                Offenlegung durch Übermittlung, Verbreitung oder eine andere
                Form der
                Bereitstellung, den Abgleich oder die Verknüpfung, die
                Einschränkung, das
                Löschen oder die Vernichtung.
              </li>
              <li><strong>d) Einschränkung der Verarbeitung</strong>Einschränkung
                der
                Verarbeitung ist die Markierung gespeicherter personenbezogener
                Daten mit
                dem Ziel, ihre künftige Verarbeitung einzuschränken.
              </li>
              <li><strong>e) Profiling</strong>Profiling ist jede Art der
                automatisierten
                Verarbeitung personenbezogener Daten, die darin besteht, dass
                diese
                personenbezogenen Daten verwendet werden, um bestimmte
                persönliche
                Aspekte, die sich auf eine natürliche Person beziehen, zu
                bewerten,
                insbesondere, um Aspekte bezüglich Arbeitsleistung,
                wirtschaftlicher Lage,
                Gesundheit, persönlicher Vorlieben, Interessen, Zuverlässigkeit,
                Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen
                Person zu
                analysieren oder vorherzusagen.
              </li>
              <li><strong>f) Pseudonymisierung</strong>Pseudonymisierung ist die
                Verarbeitung personenbezogener Daten in einer Weise, auf welche
                die
                personenbezogenen Daten ohne Hinzuziehung zusätzlicher
                Informationen nicht
                mehr einer spezifischen betroffenen Person zugeordnet werden
                können,
                sofern diese zusätzlichen Informationen gesondert aufbewahrt
                werden und
                technischen und organisatorischen Maßnahmen unterliegen, die
                gewährleisten, dass die personenbezogenen Daten nicht einer
                identifizierten oder identifizierbaren natürlichen Person
                zugewiesen
                werden.
              </li>
              <li><strong>g) Verantwortlicher oder für die Verarbeitung
                Verantwortlicher</strong>Verantwortlicher oder für die
                Verarbeitung
                Verantwortlicher ist die natürliche oder juristische Person,
                Behörde,
                Einrichtung oder andere Stelle, die allein oder gemeinsam mit
                anderen über
                die Zwecke und Mittel der Verarbeitung von personenbezogenen
                Daten
                entscheidet. Sind die Zwecke und Mittel dieser Verarbeitung
                durch das
                Unionsrecht oder das Recht der Mitgliedstaaten vorgegeben, so
                kann der
                Verantwortliche beziehungsweise können die bestimmten Kriterien
                seiner
                Benennung nach dem Unionsrecht oder dem Recht der
                Mitgliedstaaten
                vorgesehen werden.
              </li>
              <li><strong>h) Auftragsverarbeiter</strong>Auftragsverarbeiter ist
                eine
                natürliche oder juristische Person, Behörde, Einrichtung oder
                andere
                Stelle, die personenbezogene Daten im Auftrag des
                Verantwortlichen
                verarbeitet.
              </li>
              <li><strong>i) Empfänger</strong>Empfänger ist eine natürliche
                oder
                juristische Person, Behörde, Einrichtung oder andere Stelle, der
                personenbezogene Daten offengelegt werden, unabhängig davon, ob
                es sich
                bei ihr um einen Dritten handelt oder nicht. Behörden, die im
                Rahmen eines
                bestimmten Untersuchungsauftrags nach dem Unionsrecht oder dem
                Recht der
                Mitgliedstaaten möglicherweise personenbezogene Daten erhalten,
                gelten
                jedoch nicht als Empfänger.
              </li>
              <li><strong>j) Dritter</strong>Dritter ist eine natürliche oder
                juristische
                Person, Behörde, Einrichtung oder andere Stelle außer der
                betroffenen
                Person, dem Verantwortlichen, dem Auftragsverarbeiter und den
                Personen,
                die unter der unmittelbaren Verantwortung des Verantwortlichen
                oder des
                Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu
                verarbeiten.
              </li>
              <li><strong>k) Einwilligung</strong>Einwilligung ist jede von der
                betroffenen Person freiwillig für den bestimmten Fall in
                informierter
                Weise und unmissverständlich abgegebene Willensbekundung in Form
                einer
                Erklärung oder einer sonstigen eindeutigen bestätigenden
                Handlung, mit der
                die betroffene Person zu verstehen gibt, dass sie mit der
                Verarbeitung der
                sie betreffenden personenbezogenen Daten einverstanden ist.
              </li>
            </ul>

            <p><strong>2. Name und Anschrift des für die Verarbeitung
              Verantwortlichen</strong></p>

            <p>Verantwortlicher im Sinne der Datenschutz-Grundverordnung,
              sonstiger in den
              Mitgliedstaaten der Europäischen Union geltenden
              Datenschutzgesetze und
              anderer Bestimmungen mit datenschutzrechtlichem Charakter ist
              die:</p>

            <p>Jürgen Kilchert<br/> Egerländerstraße
              395<br/> Warmensteinach<br/> Tel.: <br/> <br/> Website:
              www.holz-und-glasvisionen.de
            </p>

            <p><strong>3. Cookies</strong></p>

            <p>WIr verwenden Cookies. Cookies sind Textdateien, welche über
              einen
              Internetbrowser auf einem Computersystem abgelegt und gespeichert
              werden.<br/> Zahlreiche Internetseiten und Server verwenden
              Cookies. Viele
              Cookies enthalten eine sogenannte Cookie-ID. Eine Cookie-ID ist
              eine
              eindeutige Kennung des Cookies. Sie besteht aus einer
              Zeichenfolge, durch
              welche Internetseiten und Server dem konkreten Internetbrowser
              zugeordnet
              werden können, in dem das Cookie gespeichert wurde. Dies
              ermöglicht es den
              besuchten Internetseiten und Servern, den individuellen Browser
              der
              betroffenen Person von anderen Internetbrowsern, die andere
              Cookies
              enthalten, zu unterscheiden. Ein bestimmter Internetbrowser kann
              über die
              eindeutige Cookie-ID wiedererkannt und identifiziert
              werden.<br/> Durch den
              Einsatz von Cookies können wir den Nutzern dieser Internetseite
              nutzerfreundlichere Services bereitstellen, die ohne die
              Cookie-Setzung
              nicht möglich wären.<br/> Mittels eines Cookies können die
              Informationen
              und Angebote auf unserer Internetseite im Sinne des Benutzers
              optimiert werden. Cookies ermöglichen uns, wie bereits erwähnt,
              die
              Benutzer unserer Internetseite wiederzuerkennen. Zweck dieser
              Wiedererkennung ist es, den Nutzern die Verwendung unserer
              Internetseite zu erleichtern. Der Benutzer einer Internetseite,
              die
              Cookies verwendet, muss beispielsweise nicht bei jedem Besuch der
              Internetseite erneut seine Zugangsdaten eingeben, weil dies von
              der
              Internetseite und dem auf dem Computersystem des Benutzers
              abgelegten
              Cookie übernommen wird. Ein weiteres Beispiel ist das Cookie eines
              Warenkorbes im Online-Shop. Der Online-Shop merkt sich die
              Artikel,
              die ein Kunde in den virtuellen Warenkorb gelegt hat, über ein
              Cookie.<br/> Die
              betroffene Person kann die Setzung von Cookies durch unsere
              Internetseite jederzeit mittels einer entsprechenden Einstellung
              des
              genutzten Internetbrowsers verhindern und damit der Setzung von
              Cookies dauerhaft widersprechen. Ferner können bereits gesetzte
              Cookies jederzeit über einen Internetbrowser oder andere
              Softwareprogramme gelöscht werden. Dies ist in allen gängigen
              Internetbrowsern möglich. Deaktiviert die betroffene Person die
              Setzung von Cookies in dem genutzten Internetbrowser, sind unter
              Umständen nicht alle Funktionen unserer Internetseite
              vollumfänglich
              nutzbar.</p>

            <p><strong>4. Erfassung von allgemeinen Daten und
              Informationen</strong></p>

            <p>Unsere Internetseite erfasst mit jedem Aufruf der Internetseite
              durch eine
              betroffene Person oder ein automatisiertes System eine Reihe von
              allgemeinen
              Daten und Informationen. Diese allgemeinen Daten und Informationen
              werden in
              den Logfiles des Servers gespeichert. Erfasst werden können die
              (1)
              verwendeten Browsertypen und Versionen, (2) das vom zugreifenden
              System
              verwendete Betriebssystem, (3) die Internetseite, von welcher ein
              zugreifendes System auf unsere Internetseite gelangt (sogenannte
              Referrer),
              (4) die Unterwebseiten, welche über ein zugreifendes System auf
              unserer
              Internetseite angesteuert werden, (5) das Datum und die Uhrzeit
              eines
              Zugriffs auf die Internetseite, (6) eine
              Internet-Protokoll-Adresse
              (IP-Adresse), (7) der Internet-Service-Provider des zugreifenden
              Systems und
              (8) sonstige ähnliche Daten und Informationen, die der
              Gefahrenabwehr im
              Falle von Angriffen auf unsere informationstechnologischen Systeme
              dienen.<br/> Bei der Nutzung dieser allgemeinen Daten und
              Informationen ziehen
              wir keine Rückschlüsse auf die betroffene Person. Diese
              Informationen
              werden vielmehr benötigt, um (1) die Inhalte unserer Internetseite
              korrekt
              auszuliefern, (2) die Inhalte unserer Internetseite sowie die
              Werbung für
              diese zu optimieren, (3) die dauerhafte Funktionsfähigkeit unserer
              informationstechnologischen Systeme und der Technik unserer
              Internetseite
              zu gewährleisten sowie (4) um Strafverfolgungsbehörden im Falle
              eines
              Cyberangriffes die zur Strafverfolgung notwendigen Informationen
              bereitzustellen. Diese anonym erhobenen Daten und Informationen
              werden
              durch uns einerseits statistisch und ferner mit dem Ziel
              ausgewertet, den
              Datenschutz und die Datensicherheit in unserem Unternehmen zu
              erhöhen, um
              letztlich ein optimales Schutzniveau für die von uns verarbeiteten
              personenbezogenen Daten sicherzustellen. Die anonymen Daten der
              Server-Logfiles werden getrennt von allen durch eine betroffene
              Person
              angegebenen personenbezogenen Daten gespeichert.</p>

            <p><strong>5. Kontaktmöglichkeit über die Internetseite</strong></p>

            <p>Unsere Internetseite enthält aufgrund von gesetzlichen
              Vorschriften
              Angaben, die eine schnelle elektronische Kontaktaufnahme zu
              unserem
              Unternehmen sowie eine unmittelbare Kommunikation mit uns
              ermöglichen, was
              ebenfalls eine allgemeine Adresse der sogenannten elektronischen
              Post
              (E-Mail-Adresse) umfasst. Sofern eine betroffene Person per E-Mail
              oder über
              ein Kontaktformular den Kontakt mit dem für die Verarbeitung
              Verantwortlichen aufnimmt, werden die von der betroffenen Person
              übermittelten personenbezogenen Daten automatisch gespeichert.
              Solche auf
              freiwilliger Basis von einer betroffenen Person an den für die
              Verarbeitung
              Verantwortlichen übermittelten personenbezogenen Daten werden für
              Zwecke der
              Bearbeitung oder der Kontaktaufnahme zur betroffenen Person
              gespeichert. Es
              erfolgt keine Weitergabe dieser personenbezogenen Daten an
              Dritte.</p>

            <p><strong>6. Routinemäßige Löschung und Sperrung von
              personenbezogenen
              Daten</strong></p>

            <p>Der für die Verarbeitung Verantwortliche verarbeitet und
              speichert
              personenbezogene Daten der betroffenen Person nur für den
              Zeitraum, der zur
              Erreichung des Speicherungszwecks erforderlich ist oder sofern
              dies durch
              den Europäischen Richtlinien- und Verordnungsgeber oder einen
              anderen
              Gesetzgeber in Gesetzen oder Vorschriften, welchen der für die
              Verarbeitung
              Verantwortliche unterliegt, vorgesehen wurde.</p>

            <p>Entfällt der Speicherungszweck oder läuft eine vom Europäischen
              Richtlinien- und Verordnungsgeber oder einem anderen zuständigen
              Gesetzgeber
              vorgeschriebene Speicherfrist ab, werden die personenbezogenen
              Daten
              routinemäßig und entsprechend den gesetzlichen Vorschriften
              gesperrt oder
              gelöscht.</p>

            <p><strong>7. Rechte der betroffenen Person</strong></p>

            <ul>
              <li><strong>a) Recht auf Bestätigung</strong><br/> Jede betroffene
                Person hat
                das vom Europäischen Richtlinien- und Verordnungsgeber
                eingeräumte Recht,
                von dem für die Verarbeitung Verantwortlichen eine Bestätigung
                darüber zu
                verlangen, ob sie betreffende personenbezogene Daten verarbeitet
                werden.
                Möchte eine betroffene Person dieses Bestätigungsrecht in
                Anspruch nehmen,
                kann sie sich hierzu jederzeit an einen Mitarbeiter des für die
                Verarbeitung Verantwortlichen wenden.
              </li>
              <li><strong>b) Recht auf Auskunft</strong><br/> Jede von der
                Verarbeitung
                personenbezogener Daten betroffene Person hat das vom
                Europäischen
                Richtlinien- und Verordnungsgeber gewährte Recht, jederzeit von
                dem für
                die Verarbeitung Verantwortlichen unentgeltliche Auskunft über
                die zu
                seiner Person gespeicherten personenbezogenen Daten und eine
                Kopie dieser
                Auskunft zu erhalten. Ferner hat der Europäische Richtlinien-
                und
                Verordnungsgeber der betroffenen Person Auskunft über folgende
                Informationen zugestanden:
                <ul>
                  <li>die Verarbeitungszwecke</li>
                  <li>die Kategorien personenbezogener Daten, die verarbeitet
                    werden
                  </li>
                  <li>die Empfänger oder Kategorien von Empfängern, gegenüber
                    denen die
                    personenbezogenen Daten offengelegt worden sind oder noch
                    offengelegt
                    werden, insbesondere bei Empfängern in Drittländern oder bei
                    internationalen Organisationen
                  </li>
                  <li>falls möglich die geplante Dauer, für die die
                    personenbezogenen
                    Daten gespeichert werden, oder, falls dies nicht möglich
                    ist, die
                    Kriterien für die Festlegung dieser Dauer
                  </li>
                  <li>das Bestehen eines Rechts auf Berichtigung oder Löschung
                    der sie
                    betreffenden personenbezogenen Daten oder auf Einschränkung
                    der
                    Verarbeitung durch den Verantwortlichen oder eines
                    Widerspruchsrechts
                    gegen diese Verarbeitung
                  </li>
                  <li>das Bestehen eines Beschwerderechts bei einer
                    Aufsichtsbehörde
                  </li>
                  <li>wenn die personenbezogenen Daten nicht bei der betroffenen
                    Person
                    erhoben werden: Alle verfügbaren Informationen über die
                    Herkunft der
                    Daten
                  </li>
                  <li>das Bestehen einer automatisierten Entscheidungsfindung
                    einschließlich Profiling gemäß Artikel 22 Abs.1 und 4 DS-GVO
                    und —
                    zumindest in diesen Fällen — aussagekräftige Informationen
                    über die
                    involvierte Logik sowie die Tragweite und die angestrebten
                    Auswirkungen einer derartigen Verarbeitung für die
                    betroffene
                    PersonFerner steht der betroffenen Person ein Auskunftsrecht
                    darüber
                    zu, ob personenbezogene Daten an ein Drittland oder an eine
                    internationale Organisation übermittelt wurden. Sofern dies
                    der Fall
                    ist, so steht der betroffenen Person im Übrigen das Recht
                    zu, Auskunft
                    über die geeigneten Garantien im Zusammenhang mit der
                    Übermittlung zu
                    erhalten.<br/> Möchte eine betroffene Person dieses
                    Auskunftsrecht in
                    Anspruch nehmen, kann sie sich hierzu jederzeit an einen
                    Mitarbeiter
                    des für die Verarbeitung Verantwortlichen wenden.
                  </li>
                </ul></li>
              <li><strong>c) Recht auf Berichtigung</strong><br/> Jede von der
                Verarbeitung
                personenbezogener Daten betroffene Person hat das vom
                Europäischen
                Richtlinien- und Verordnungsgeber gewährte Recht, die
                unverzügliche
                Berichtigung sie betreffender unrichtiger personenbezogener
                Daten zu
                verlangen. Ferner steht der betroffenen Person das Recht zu,
                unter
                Berücksichtigung der Zwecke der Verarbeitung, die
                Vervollständigung
                unvollständiger personenbezogener Daten — auch mittels einer
                ergänzenden
                Erklärung — zu verlangen.Möchte eine betroffene Person dieses
                Berichtigungsrecht in Anspruch nehmen, kann sie sich hierzu
                jederzeit an
                einen Mitarbeiter des für die Verarbeitung Verantwortlichen
                wenden.
              </li>
              <li><strong>d) Recht auf Löschung (Recht auf Vergessen
                werden)</strong><br/> Jede
                von der Verarbeitung personenbezogener Daten betroffene Person
                hat das vom
                Europäischen Richtlinien- und Verordnungsgeber gewährte Recht,
                von dem
                Verantwortlichen zu verlangen, dass die sie betreffenden
                personenbezogenen
                Daten unverzüglich gelöscht werden, sofern einer der folgenden
                Gründe
                zutrifft und soweit die Verarbeitung nicht erforderlich ist:
                <ul>
                  <li>Die personenbezogenen Daten wurden für solche Zwecke
                    erhoben oder
                    auf sonstige Weise verarbeitet, für welche sie nicht mehr
                    notwendig
                    sind.
                  </li>
                  <li>Die betroffene Person widerruft ihre Einwilligung, auf die
                    sich die
                    Verarbeitung gemäß Art. 6 Abs. 1 Buchstabe a DS-GVO oder
                    Art. 9 Abs. 2
                    Buchstabe a DS-GVO stützte, und es fehlt an einer
                    anderweitigen
                    Rechtsgrundlage für die Verarbeitung.
                  </li>
                  <li>Die betroffene Person legt gemäß Art. 21 Abs. 1 DS-GVO
                    Widerspruch
                    gegen die Verarbeitung ein, und es liegen keine vorrangigen
                    berechtigten Gründe für die Verarbeitung vor, oder die
                    betroffene
                    Person legt gemäß Art. 21 Abs. 2 DS-GVO Widerspruch gegen
                    die
                    Verarbeitung ein.
                  </li>
                  <li>Die personenbezogenen Daten wurden unrechtmäßig
                    verarbeitet.
                  </li>
                  <li>Die Löschung der personenbezogenen Daten ist zur Erfüllung
                    einer
                    rechtlichen Verpflichtung nach dem Unionsrecht oder dem
                    Recht der
                    Mitgliedstaaten erforderlich, dem der Verantwortliche
                    unterliegt.
                  </li>
                </ul></li>
              <li><strong>e) Recht auf Einschränkung der
                Verarbeitung</strong><br/> Jede von
                der Verarbeitung personenbezogener Daten betroffene Person hat
                das vom
                Europäischen Richtlinien- und Verordnungsgeber gewährte Recht,
                von dem
                Verantwortlichen die Einschränkung der Verarbeitung zu
                verlangen, wenn
                eine der folgenden Voraussetzungen gegeben ist:
                <ul>
                  <li>Die Richtigkeit der personenbezogenen Daten wird von der
                    betroffenen
                    Person bestritten, und zwar für eine Dauer, die es dem
                    Verantwortlichen ermöglicht, die Richtigkeit der
                    personenbezogenen
                    Daten zu überprüfen.
                  </li>
                  <li>Die Verarbeitung ist unrechtmäßig, die betroffene Person
                    lehnt die
                    Löschung der personenbezogenen Daten ab und verlangt
                    stattdessen die
                    Einschränkung der Nutzung der personenbezogenen Daten.
                  </li>
                  <li>Der Verantwortliche benötigt die personenbezogenen Daten
                    für die
                    Zwecke der Verarbeitung nicht länger, die betroffene Person
                    benötigt
                    sie jedoch zur Geltendmachung, Ausübung oder Verteidigung
                    von
                    Rechtsansprüchen.
                  </li>
                  <li>Die betroffene Person hat Widerspruch gegen die
                    Verarbeitung gem.
                    Art. 21 Abs. 1 DS-GVO eingelegt und es steht noch nicht
                    fest, ob die
                    berechtigten Gründe des Verantwortlichen gegenüber denen der
                    betroffenen Person überwiegen.
                  </li>
                </ul></li>
              <li><strong>f) Recht auf Datenübertragbarkeit</strong><br/> Jede
                von der
                Verarbeitung personenbezogener Daten betroffene Person hat das
                vom
                Europäischen Richtlinien- und Verordnungsgeber gewährte Recht,
                die sie
                betreffenden personenbezogenen Daten, welche durch die
                betroffene Person
                einem Verantwortlichen bereitgestellt wurden, in einem
                strukturierten,
                gängigen und maschinenlesbaren Format zu erhalten. Sie hat
                außerdem das
                Recht, diese Daten einem anderen Verantwortlichen ohne
                Behinderung durch
                den Verantwortlichen, dem die personenbezogenen Daten
                bereitgestellt
                wurden, zu übermitteln, sofern die Verarbeitung auf der
                Einwilligung gemäß
                Art. 6 Abs. 1 Buchstabe a DS-GVO oder Art. 9 Abs. 2 Buchstabe a
                DS-GVO
                oder auf einem Vertrag gemäß Art. 6 Abs. 1 Buchstabe b DS-GVO
                beruht und
                die Verarbeitung mithilfe automatisierter Verfahren erfolgt,
                sofern die
                Verarbeitung nicht für die Wahrnehmung einer Aufgabe
                erforderlich ist, die
                im öffentlichen Interesse liegt oder in Ausübung öffentlicher
                Gewalt
                erfolgt, welche dem Verantwortlichen übertragen
                wurde.<br/> Ferner hat die
                betroffene Person bei der Ausübung ihres Rechts auf
                Datenübertragbarkeit
                gemäß Art. 20 Abs. 1 DS-GVO das Recht, zu erwirken, dass die
                personenbezogenen Daten direkt von einem Verantwortlichen an
                einen
                anderen Verantwortlichen übermittelt werden, soweit dies
                technisch
                machbar ist und sofern hiervon nicht die Rechte und Freiheiten
                anderer
                Personen beeinträchtigt werden.<br/></li>
              <li><strong>g) Recht auf Widerspruch</strong><br/> Jede von der
                Verarbeitung
                personenbezogener Daten betroffene Person hat das vom
                Europäischen
                Richtlinien- und Verordnungsgeber gewährte Recht, aus Gründen,
                die sich
                aus ihrer besonderen Situation ergeben, jederzeit gegen die
                Verarbeitung
                sie betreffender personenbezogener Daten, die aufgrund von Art.
                6 Abs. 1
                Buchstaben e oder f DS-GVO erfolgt, Widerspruch einzulegen. Dies
                gilt auch
                für ein auf diese Bestimmungen gestütztes Profiling.<br/> Wir
                verarbeiten
                die personenbezogenen Daten im Falle des Widerspruchs nicht
                mehr, es sei
                denn, wir können zwingende schutzwürdige Gründe für die
                Verarbeitung
                nachweisen, die den Interessen, Rechten und Freiheiten der
                betroffenen
                Person überwiegen, oder die Verarbeitung dient der
                Geltendmachung,
                Ausübung oder Verteidigung von Rechtsansprüchen.<br/> Wir
                verarbeiten
                personenbezogene Daten, um Direktwerbung zu betreiben, so hat
                die
                betroffene Person das Recht, jederzeit Widerspruch gegen die
                Verarbeitung der personenbezogenen Daten zum Zwecke derartiger
                Werbung
                einzulegen. Dies gilt auch für das Profiling, soweit es mit
                solcher
                Direktwerbung in Verbindung steht. Widerspricht die betroffene
                Person
                gegenüber ubs der Verarbeitung für Zwecke der Direktwerbung, so
                werden
                wir die personenbezogenen Daten nicht mehr für diese Zwecke
                verarbeiten.<br/> Zudem hat die betroffene Person das Recht, aus
                Gründen, die sich aus ihrer besonderen Situation ergeben, gegen
                die
                sie betreffende Verarbeitung personenbezogener Daten, die bei
                uns zu
                wissenschaftlichen oder historischen Forschungszwecken oder zu
                statistischen Zwecken gemäß Art. 89 Abs. 1 DS-GVO erfolgen,
                Widerspruch einzulegen, es sei denn, eine solche Verarbeitung
                ist
                zur Erfüllung einer im öffentlichen Interesse liegenden Aufgabe
                erforderlich.<br/></li>
              <li><strong>h) Automatisierte Entscheidungen im Einzelfall
                einschließlich
                Profiling</strong><br/> Jede von der Verarbeitung
                personenbezogener Daten
                betroffene Person hat das vom Europäischen Richtlinien- und
                Verordnungsgeber gewährte Recht, nicht einer ausschließlich auf
                einer
                automatisierten Verarbeitung — einschließlich Profiling —
                beruhenden
                Entscheidung unterworfen zu werden, die ihr gegenüber rechtliche
                Wirkung
                entfaltet oder sie in ähnlicher Weise erheblich beeinträchtigt,
                sofern die
                Entscheidung (1) nicht für den Abschluss oder die Erfüllung
                eines Vertrags
                zwischen der betroffenen Person und dem Verantwortlichen
                erforderlich ist,
                oder (2) aufgrund von Rechtsvorschriften der Union oder der
                Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig
                ist und
                diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung der
                Rechte und
                Freiheiten sowie der berechtigten Interessen der betroffenen
                Person
                enthalten oder (3) mit ausdrücklicher Einwilligung der
                betroffenen Person
                erfolgt.Ist die Entscheidung (1) für den Abschluss oder die
                Erfüllung
                eines Vertrags zwischen der betroffenen Person und dem
                Verantwortlichen
                erforderlich oder (2)
              </li>
              <li><strong>i) Recht auf Widerruf einer datenschutzrechtlichen
                Einwilligung</strong>Jede von der Verarbeitung personenbezogener
                Daten
                betroffene Person hat das vom Europäischen Richtlinien- und
                Verordnungsgeber gewährte Recht, eine Einwilligung zur
                Verarbeitung
                personenbezogener Daten jederzeit zu widerrufen.Möchte die
                betroffene
                Person ihr Recht auf Widerruf einer Einwilligung geltend machen,
                kann sie
                sich hierzu jederzeit an einen Mitarbeiter des für die
                Verarbeitung
                Verantwortlichen wenden.
              </li>
            </ul>

            <p><strong>8. Datenschutzbestimmungen zu Einsatz und Verwendung von
              Matomo</strong></p>

            <p>Der für die Verarbeitung Verantwortliche hat auf dieser
              Internetseite die
              Komponente Matomo integriert. Matomo ist ein
              Open-Source-Softwaretool zur
              Web-Analyse. Web-Analyse ist die Erhebung, Sammlung und Auswertung
              von Daten
              über das Verhalten von Besuchern von Internetseiten. Ein
              Web-Analyse-Tool
              erfasst unter anderem Daten darüber, von welcher Internetseite
              eine
              betroffene Person auf eine Internetseite gekommen ist (sogenannter
              Referrer), auf welche Unterseiten der Internetseite zugegriffen
              oder wie oft
              und für welche Verweildauer eine Unterseite betrachtet wurde. Eine
              Web-Analyse wird überwiegend zur Optimierung einer Internetseite
              und zur
              Kosten-Nutzen-Analyse von Internetwerbung eingesetzt.<br/> Die
              Software wird
              auf dem Server des für die Verarbeitung Verantwortlichen
              betrieben, die
              datenschutzrechtlich sensiblen Logdateien werden ausschließlich
              auf diesem
              Server gespeichert.<br/> Der Zweck der Matomo-Komponente ist die
              Analyse der
              Besucherströme auf unserer Internetseite. Der für die Verarbeitung
              Verantwortliche nutzt die gewonnenen Daten und Informationen unter
              anderem dazu, die Nutzung dieser Internetseite auszuwerten, um
              Online-Reports, welche die Aktivitäten auf unseren Internetseiten
              aufzeigen, zusammenzustellen.<br/> Matomo setzt ein Cookie auf dem
              informationstechnologischen System der betroffenen Person. Was
              Cookies
              sind, wurde oben bereits erläutert. Mit der Setzung des Cookies
              wird
              uns eine Analyse der Benutzung unserer Internetseite ermöglicht.
              Durch
              jeden Aufruf einer der Einzelseiten dieser Internetseite wird der
              Internetbrowser auf dem informationstechnologischen System der
              betroffenen Person automatisch durch die Matomo-Komponente
              veranlasst,
              Daten zum Zwecke der Online-Analyse an unseren Server zu
              übermitteln.
              Im Rahmen dieses technischen Verfahrens erhalten wir Kenntnis über
              personenbezogene Daten, wie der IP-Adresse der betroffenen Person,
              die
              uns unter anderem dazu dient, die Herkunft der Besucher und Klicks
              nachzuvollziehen.<br/> Mittels des Cookies werden personenbezogene
              Informationen, beispielsweise die Zugriffszeit, der Ort, von
              welchem
              ein Zugriff ausging und die Häufigkeit der Besuche auf unserer
              Internetseite gespeichert. Bei jedem Besuch unserer Internetseiten
              werden diese personenbezogenen Daten, einschließlich der
              IP-Adresse
              des von der betroffenen Person genutzten Internetanschlusses, an
              unseren Server übertragen. Diese personenbezogenen Daten werden
              durch uns gespeichert. Wir geben diese personenbezogenen Daten
              nicht
              an Dritte weiter.<br/> Die betroffene Person kann die Setzung von
              Cookies durch unsere Internetseite, wie oben bereits dargestellt,
              jederzeit mittels einer entsprechenden Einstellung des genutzten
              Internetbrowsers verhindern und damit der Setzung von Cookies
              dauerhaft widersprechen. Eine solche Einstellung des genutzten
              Internetbrowsers würde auch verhindern, dass Matomo ein Cookie auf
              dem informationstechnologischen System der betroffenen Person
              setzt. Zudem kann ein von Matomo bereits gesetzter Cookie
              jederzeit über einen Internetbrowser oder andere Softwareprogramme
              gelöscht werden.<br/> Ferner besteht für die betroffene Person die
              Möglichkeit, einer Erfassung der durch den Matomo erzeugten, auf
              eine Nutzung dieser Internetseite bezogenen Daten zu
              widersprechen und eine solche zu verhindern. Hierzu muss die
              betroffene Person ein Opt-Out-Cookie setzen. Wird das
              informationstechnologische System der betroffenen Person zu
              einem späteren Zeitpunkt gelöscht, formatiert oder neu
              installiert, muss die betroffene Person erneut einen
              Opt-Out-Cookie setzen.<br/> Mit der Setzung des Opt-Out-Cookies
              besteht jedoch die Möglichkeit, dass die Internetseiten des
              für die Verarbeitung Verantwortlichen für die betroffene
              Person nicht mehr vollumfänglich nutzbar
              sind. Weitere Informationen und die geltenden
              Datenschutzbestimmungen von
              Matomo können unter <a
                  href="https://matomo.org/privacy/" target="_blank"
                  rel="noreferrer noopener">matomo.org/privacy/</a> abgerufen
              werden.</p>

            <p><strong>9. Rechtsgrundlage der Verarbeitung</strong></p>

            <p>Art. 6 I lit. a DS-GVO dient unserem Unternehmen als
              Rechtsgrundlage für
              Verarbeitungsvorgänge, bei denen wir eine Einwilligung für einen
              bestimmten
              Verarbeitungszweck einholen. Ist die Verarbeitung
              personenbezogener Daten
              zur Erfüllung eines Vertrags, dessen Vertragspartei die betroffene
              Person
              ist, erforderlich, wie dies beispielsweise bei
              Verarbeitungsvorgängen der
              Fall ist, die für eine Lieferung von Waren oder die Erbringung
              einer
              sonstigen Leistung oder Gegenleistung notwendig sind, so beruht
              die
              Verarbeitung auf Art. 6 I lit. b DS-GVO. Gleiches gilt für solche
              Verarbeitungsvorgänge die zur Durchführung vorvertraglicher
              Maßnahmen
              erforderlich sind, etwa in Fällen von Anfragen zur unseren
              Produkten oder
              Leistungen. Unterliegt unser Unternehmen einer rechtlichen
              Verpflichtung
              durch welche eine Verarbeitung von personenbezogenen Daten
              erforderlich
              wird, wie beispielsweise zur Erfüllung steuerlicher Pflichten, so
              basiert
              die Verarbeitung auf Art. 6 I lit. c DS-GVO. In seltenen Fällen
              könnte die
              Verarbeitung von personenbezogenen Daten erforderlich werden, um
              lebenswichtige Interessen der betroffenen Person oder einer
              anderen
              natürlichen Person zu schützen. Dies wäre beispielsweise der Fall,
              wenn ein
              Besucher in unserem Betrieb verletzt werden würde und daraufhin
              sein Name,
              sein Alter, seine Krankenkassendaten oder sonstige lebenswichtige
              Informationen an einen Arzt, ein Krankenhaus oder sonstige Dritte
              weitergegeben werden müssten. Dann würde die Verarbeitung auf Art.
              6 I lit.
              d DS-GVO beruhen. Letztlich könnten Verarbeitungsvorgänge auf Art.
              6 I lit.
              f DS-GVO beruhen. Auf dieser Rechtsgrundlage basieren
              Verarbeitungsvorgänge,
              die von keiner der vorgenannten Rechtsgrundlagen erfasst werden,
              wenn die
              Verarbeitung zur Wahrung eines berechtigten Interesses unseres
              Unternehmens
              oder eines Dritten erforderlich ist, sofern die Interessen,
              Grundrechte und
              Grundfreiheiten des Betroffenen nicht überwiegen. Solche
              Verarbeitungsvorgänge sind uns insbesondere deshalb gestattet,
              weil sie
              durch den Europäischen Gesetzgeber besonders erwähnt wurden. Er
              vertrat
              insoweit die Auffassung, dass ein berechtigtes Interesse
              anzunehmen sein
              könnte, wenn die betroffene Person ein Kunde des Verantwortlichen
              ist
              (Erwägungsgrund 47 Satz 2 DS-GVO).</p>

            <p><strong>10. Berechtigte Interessen an der Verarbeitung, die von
              dem
              Verantwortlichen oder einem Dritten verfolgt werden</strong></p>

            <p>Basiert die Verarbeitung personenbezogener Daten auf Artikel 6 I
              lit. f
              DS-GVO ist unser berechtigtes Interesse die Durchführung unserer
              Geschäftstätigkeit zugunsten des Wohlergehens all unserer
              Mitarbeiter und
              unserer Anteilseigner.</p>

            <p><strong>11. Dauer, für die die personenbezogenen Daten
              gespeichert
              werden</strong></p>

            <p>Das Kriterium für die Dauer der Speicherung von personenbezogenen
              Daten ist
              die jeweilige gesetzliche Aufbewahrungsfrist. Nach Ablauf der
              Frist werden
              die entsprechenden Daten routinemäßig gelöscht, sofern sie nicht
              mehr zur
              Vertragserfüllung oder Vertragsanbahnung erforderlich sind.</p>

            <p><strong>12. Gesetzliche oder vertragliche Vorschriften zur
              Bereitstellung
              der personenbezogenen Daten; Erforderlichkeit für den
              Vertragsabschluss;
              Verpflichtung der betroffenen Person, die personenbezogenen Daten
              bereitzustellen; mögliche Folgen der Nichtbereitstellung</strong>
            </p>

            <p>Wir klären Sie darüber auf, dass die Bereitstellung
              personenbezogener Daten
              zum Teil gesetzlich vorgeschrieben ist (z.B. Steuervorschriften)
              oder sich
              auch aus vertraglichen Regelungen (z.B. Angaben zum
              Vertragspartner) ergeben
              kann. Mitunter kann es zu einem Vertragsschluss erforderlich sein,
              dass eine
              betroffene Person uns personenbezogene Daten zur Verfügung stellt,
              die in
              der Folge durch uns verarbeitet werden müssen. Die betroffene
              Person ist
              beispielsweise verpflichtet uns personenbezogene Daten
              bereitzustellen, wenn
              unser Unternehmen mit ihr einen Vertrag abschließt. Eine
              Nichtbereitstellung
              der personenbezogenen Daten hätte zur Folge, dass der Vertrag mit
              dem
              Betroffenen nicht geschlossen werden könnte. Vor einer
              Bereitstellung
              personenbezogener Daten durch den Betroffenen muss sich der
              Betroffene an
              einen unserer Mitarbeiter wenden. Unser Mitarbeiter klärt den
              Betroffenen
              einzelfallbezogen darüber auf, ob die Bereitstellung der
              personenbezogenen
              Daten gesetzlich oder vertraglich vorgeschrieben oder für den
              Vertragsabschluss erforderlich ist, ob eine Verpflichtung besteht,
              die
              personenbezogenen Daten bereitzustellen, und welche Folgen die
              Nichtbereitstellung der personenbezogenen Daten hätte.</p>

            <p><strong>13. Bestehen einer automatisierten
              Entscheidungsfindung</strong>
            </p>

            <p>Als verantwortungsbewusstes Unternehmen verzichten wir auf eine
              automatische Entscheidungsfindung oder ein Profiling.</p>

            <p><strong>14. Einsatz von Google-Maps</strong></p>

            <p>Wir setzen auf unserer Seite die Komponente &#8222;Google
              Maps&#8220; der
              Firma Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA
              94043 USA,
              nachfolgend „Google“, ein.<br/> Bei jedem einzelnen Aufruf der
              Komponente &#8222;Google Maps&#8220; wird von Google ein Cookie
              gesetzt,
              um bei der Anzeige der Seite, auf der die Komponente &#8222;Google
              Maps&#8220; integriert ist, Nutzereinstellungen und -daten zu
              verarbeiten.
              Dieses Cookie wird im Regelfall nicht durch das Schließen des
              Browsers
              gelöscht, sondern läuft nach einer bestimmten Zeit ab, soweit es
              nicht von
              Ihnen zuvor manuell gelöscht wird.<br/> Wenn Sie mit dieser
              Verarbeitung
              Ihrer Daten nicht einverstanden sind, so besteht die Möglichkeit,
              den
              Service von &#8222;Google Maps&#8220; zu deaktivieren und auf
              diesem Weg
              die Übertragung von Daten an Google zu verhindern. Dazu müssen Sie
              die
              Java-Script-Funktion in Ihrem Browser deaktivieren. Wir weisen Sie
              jedoch darauf hin, dass Sie in diesem Fall die &#8222;Google
              Maps&#8220; nicht oder nur eingeschränkt nutzen können.<br/> Die
              Nutzung
              von &#8222;Google Maps&#8220; und der über &#8222;Google
              Maps&#8220; erlangten Informationen erfolgt gemäß den
              Google-Nutzungsbedingungen<br/> <a
                  href="http://www.google.de/intl/de/policies/terms/regional.html"
                  target="_blank"
                  rel="noreferrer noopener">www.google.de/intl/de/policies/terms/regional.html</a><br/> sowie
              der zusätzlichen Geschäftsbedingungen für „Google Maps“<br/> <a
                  href="https://www.google.com/intl/de_de/help/terms_maps.html"
                  target="_blank"
                  rel="noreferrer noopener">www.google.com/intl/de_de/help/terms_maps.html</a>.
            </p>

            <p><strong>15. Google Web Fonts</strong></p>

            <p>Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten
              so
              genannte Web Fonts, die von Google bereitgestellt werden. Beim
              Aufruf einer
              Seite lädt Ihr Browser die benötigten Web Fonts in ihren
              Browsercache, um
              Texte und Schriftarten korrekt anzuzeigen.<br/> Zu diesem Zweck
              muss der von
              Ihnen verwendete Browser Verbindung zu den Servern von Google
              aufnehmen.
              Hierdurch erlangt Google Kenntnis darüber, dass über Ihre
              IP-Adresse
              unsere Website aufgerufen wurde. Die Nutzung von Google Web Fonts
              erfolgt
              im Interesse einer einheitlichen und ansprechenden Darstellung
              unserer
              Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne
              von Art.
              6 Abs. 1 lit. f DSGVO dar.<br/> Wenn Ihr Browser Web Fonts nicht
              unterstützt, wird eine Standardschrift von Ihrem Computer genutzt.<br/> Weitere
              Informationen zu Google Web Fonts finden Sie unter <a
                  href="https://developers.google.com/fonts/faq" target="_blank"
                  rel="noreferrer noopener">developers.google.com/fonts/faq</a> und
              in der Datenschutzerklärung von Google: <a
                  href="https://www.google.com/policies/privacy/"
                  target="_blank"
                  rel="noreferrer noopener">www.google.com/policies/privacy/</a>.<br/>
            </p>

            <p><strong>16. Bestellabwicklung im Onlineshop und
              Kundenkonto</strong></p>

            <p>Wir verarbeiten die Daten unserer Kunden im Rahmen der
              Bestellvorgänge in
              unserem Onlineshop, um ihnen die Auswahl und die Bestellung der
              gewählten
              Produkte und Leistungen, sowie deren Bezahlung und Zustellung,
              bzw.
              Ausführung zu ermöglichen.<br/> <br/> Zu den verarbeiteten Daten
              gehören
              Bestandsdaten, Kommunikationsdaten, Vertragsdaten, Zahlungsdaten
              und zu
              den von der Verarbeitung betroffenen Personen gehören unsere
              Kunden,
              Interessenten und sonstige Geschäftspartner. Die Verarbeitung
              erfolgt zum
              Zweck der Erbringung von Vertragsleistungen im Rahmen des Betriebs
              eines
              Onlineshops, Abrechnung, Auslieferung und der Kundenservices.
              Hierbei
              setzen wir Session Cookies für die Speicherung des
              Warenkorb-Inhalts und
              permanente Cookies für die Speicherung des Login-Status ein.<br/>
              <br/> Die
              Verarbeitung erfolgt zur Erfüllung unserer Leistungen und
              Durchführung
              vertraglicher Maßnahmen (z.B. Durchführung von Bestellvorgängen)
              und
              soweit sie gesetzlich vorgeschrieben ist (z.B., gesetzlich
              erforderliche
              Archivierung von Geschäftsvorgängen zu Handels und Steuerzwecken).
              Dabei
              sind die als erforderlich gekennzeichneten Angaben zur Begründung
              und
              Erfüllung des Vertrages erforderlich. Die Daten offenbaren wir
              gegenüber
              Dritten nur im Rahmen der Auslieferung, Zahlung oder im Rahmen der
              gesetzlichen Erlaubnisse und Pflichten, als auch wenn dies auf
              Grundlage
              unserer berechtigten Interessen erfolgt, worüber wir Sie im Rahmen
              dieser Datenschutzerklärung informieren (z.B., gegenüber Rechts-
              und
              Steuerberatern, Finanzinstituten, Frachtunternehmen sowie
              Behörden).<br/> <br/> Nutzer
              können optional ein Nutzerkonto anlegen, indem sie insbesondere
              ihre
              Bestellungen einsehen können. Im Rahmen der Registrierung, werden
              die
              erforderlichen Pflichtangaben den Nutzern mitgeteilt. Die
              Nutzerkonten
              sind nicht öffentlich und können von Suchmaschinen nicht indexiert
              werden. Wenn Nutzer ihr Nutzerkonto gekündigt haben, werden deren
              Daten im Hinblick auf das Nutzerkonto gelöscht, vorbehaltlich
              deren
              Aufbewahrung ist aus handels- oder steuerrechtlichen Gründen
              notwendig. Angaben im Kundenkonto verbleiben bis zu dessen
              Löschung
              mit anschließender Archivierung im Fall einer rechtlichen
              Verpflichtung oder unser berechtigten Interessen (z.B., im Fall
              von
              Rechtsstreitigkeiten). Es obliegt den Nutzern, ihre Daten bei
              erfolgter Kündigung vor dem Vertragsende zu sichern.<br/> <br/> Im
              Rahmen
              der Registrierung und erneuter Anmeldungen sowie Inanspruchnahme
              unserer Onlinedienste, speichern wir die IP-Adresse und den
              Zeitpunkt der jeweiligen Nutzerhandlung. Die Speicherung erfolgt
              auf
              Grundlage unserer berechtigten Interessen, als auch der Nutzer an
              Schutz vor Missbrauch und sonstiger unbefugter Nutzung. Eine
              Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht,
              außer
              sie ist zur Verfolgung unserer gesetzlichen Ansprüche als
              berechtigtes Interesse erforderlich oder es besteht hierzu eine
              gesetzliche Verpflichtung.<br/> <br/> Die Löschung erfolgt nach
              Ablauf
              gesetzlicher Gewährleistungs- und sonstiger vertraglicher Rechte
              oder Pflichten (z.B., Zahlungsansprüche oder Leistungspflichten
              aus Verträgen mir Kunden), wobei die Erforderlichkeit der
              Aufbewahrung der Daten alle drei Jahre überprüft wird; im Fall der
              Aufbewahrung aufgrund gesetzlicher Archivierungspflichten, erfolgt
              die Löschung insoweit nach deren Ablauf.</p>

            <p><strong>17. Externe Zahlungsdienstleister</strong></p>

            <p>Wir setzen externe Zahlungsdienstleister ein, über deren
              Plattformen die
              Nutzer und wir Zahlungstransaktionen vornehmen können. Zu diesen
              Zahlungsdienstleistern können gehören, jeweils mit Link zur
              Datenschutzerklärung: Paypal (<a
                  href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full"
                  target="_blank"
                  rel="noreferrer noopener">https://www.paypal.com/de/webapps/mpp/ua/privacy-full</a>),
              Klarna (<a href="https://www.klarna.com/de/datenschutz/"
                         target="_blank"
                         rel="noreferrer noopener">https://www.klarna.com/de/datenschutz/</a>),
              Skrill (<a
                  href="https://www.skrill.com/de/fusszeile/datenschutzrichtlinie/"
                  target="_blank"
                  rel="noreferrer noopener">https://www.skrill.com/de/fusszeile/datenschutzrichtlinie/</a>),
              Giropay (<a
                  href="https://www.giropay.de/rechtliches/datenschutz-agb/"
                  target="_blank"
                  rel="noreferrer noopener">https://www.giropay.de/rechtliches/datenschutz-agb/</a>),
              Visa (<a href="https://www.visa.de/datenschutz" target="_blank"
                       rel="noreferrer noopener">https://www.visa.de/datenschutz</a>),
              Mastercard (<a
                  href="https://www.mastercard.de/de-de/datenschutz.html"
                  target="_blank"
                  rel="noreferrer noopener">https://www.mastercard.de/de-de/datenschutz.html</a>),
              American Express (<a
                  href="https://www.americanexpress.com/de/content/privacy-policy-statement.html"
                  target="_blank"
                  rel="noreferrer noopener">https://www.americanexpress.com/de/content/privacy-policy-statement.html</a>),
              Stripe (<a href="https://stripe.com/de/privacy" target="_blank"
                         rel="noreferrer noopener">https://stripe.com/de/privacy</a>).<br/>
              <br/> Im
              Rahmen der Erfüllung von Verträgen setzen wir die
              Zahlungsdienstleister auf Grundlage des Art. 6 Abs. 1 lit. b.
              DSGVO ein.
              Im Übrigen setzen wir externe Zahlungsdienstleister auf
              Grundlage
              unserer berechtigten Interessen gem. Art. 6 Abs. 1 lit. f. DSGVO
              ein, um
              unseren Nutzern effektive und sichere Zahlungsmöglichkeit zu
              bieten.<br/> <br/> Zu den, durch die Zahlungsdienstleister
              verarbeiteten Daten
              gehören Bestandsdaten, wie z.B. der Name und die Adresse,
              Bankdaten, wie
              z.B. Kontonummern oder Kreditkartennummern, Passwörter, TANs und
              Prüfsummen sowie die Vertrags-, Summen und empfängerbezogenen
              Angaben.
              Die Angaben sind erforderlich, um die Transaktionen durchzuführen.
              Die
              eingegebenen Daten werden jedoch nur durch die
              Zahlungsdienstleister
              verarbeitet und bei diesen gespeichert. D.h. wir erhalten keine
              konto-
              oder kreditkartenbezogenen Informationen, sondern lediglich
              Informationen mit Bestätigung oder Negativbeauskunftung der
              Zahlung. Unter Umständen werden die Daten seitens der
              Zahlungsdienstleister an Wirtschaftsauskunfteien übermittelt.
              Diese
              Übermittlung bezweckt die Identitäts- und Bonitätsprüfung. Hierzu
              verweisen wir auf die AGB und Datenschutzhinweise
              der Zahlungsdienstleister.<br/> <br/> Für die
              Zahlungsgeschäfte gelten
              die Geschäftsbedingungen und die Datenschutzhinweise der
              jeweiligen
              Zahlungsdienstleister, welche innerhalb der jeweiligen Webseiten,
              bzw.
              Transaktionsapplikationen abrufbar sind. Wir verweisen auf diese
              ebenfalls zwecks weiterer Informationen und Geltendmachung von
              Widerrufs-, Auskunfts- und anderen Betroffenenrechten.</p>

            <p><strong>18. Administration, Finanzbuchhaltung, Büroorganisation,
              Kontaktverwaltung</strong></p>

            <p>Wir verarbeiten Daten im Rahmen von Verwaltungsaufgaben sowie
              Organisation
              unseres Betriebs, Finanzbuchhaltung und Befolgung der gesetzlichen
              Pflichten, wie z.B. der Archivierung. Hierbei verarbeiten wir
              dieselben
              Daten, die wir im Rahmen der Erbringung unserer vertraglichen
              Leistungen
              verarbeiten. Die Verarbeitungsgrundlagen sind Art. 6 Abs. 1 lit.
              c. DSGVO,
              Art. 6 Abs. 1 lit. f. DSGVO. Von der Verarbeitung sind Kunden,
              Interessenten, Geschäftspartner und Websitebesucher betroffen. Der
              Zweck und
              unser Interesse an der Verarbeitung liegt in der Administration,
              Finanzbuchhaltung, Büroorganisation, Archivierung von Daten, also
              Aufgaben
              die der Aufrechterhaltung unserer Geschäftstätigkeiten,
              Wahrnehmung unserer
              Aufgaben und Erbringung unserer Leistungen dienen. Die Löschung
              der Daten im
              Hinblick auf vertragliche Leistungen und die vertragliche
              Kommunikation
              entspricht den, bei diesen Verarbeitungstätigkeiten genannten
              Angaben.<br/> <br/> Wir offenbaren oder übermitteln hierbei Daten
              an die
              Finanzverwaltung, Berater, wie z.B., Steuerberater oder
              Wirtschaftsprüfer
              sowie weitere Gebührenstellen und Zahlungsdienstleister.<br/>
              <br/> Ferner
              speichern wir auf Grundlage unserer betriebswirtschaftlichen
              Interessen
              Angaben zu Lieferanten, Veranstaltern und sonstigen
              Geschäftspartnern,
              z.B. zwecks späterer Kontaktaufnahme. Diese mehrheitlich
              unternehmensbezogenen Daten, speichern wir grundsätzlich
              dauerhaft.</p>

            <p><strong>19. Betriebswirtschaftliche Analysen und
              Marktforschung</strong>
            </p>

            <p>Um unser Geschäft wirtschaftlich betreiben, Markttendenzen,
              Wünsche der
              Vertragspartner und Nutzer erkennen zu können, analysieren wir die
              uns
              vorliegenden Daten zu Geschäftsvorgängen, Verträgen, Anfragen,
              etc. Wir
              verarbeiten dabei Bestandsdaten, Kommunikationsdaten,
              Vertragsdaten,
              Zahlungsdaten, Nutzungsdaten, Metadaten auf Grundlage des Art. 6
              Abs. 1 lit.
              f. DSGVO, wobei zu den betroffenen Personen Vertragspartner,
              Interessenten,
              Kunden, Besucher und Nutzer unseres Onlineangebotes gehören.<br/>
              <br/> Die
              Analysen erfolgen zum Zweck betriebswirtschaftlicher Auswertungen,
              des
              Marketings und der Marktforschung. Dabei können wir die Profile
              der
              registrierten Nutzer mit Angaben, z.B. zu deren in Anspruch
              genommenen
              Leistungen, berücksichtigen. Die Analysen dienen uns zur
              Steigerung der
              Nutzerfreundlichkeit, der Optimierung unseres Angebotes und der
              Betriebswirtschaftlichkeit. Die Analysen dienen alleine uns und
              werden
              nicht extern offenbart, sofern es sich nicht um anonyme Analysen
              mit
              zusammengefassten Werten handelt.<br/> <br/> Sofern diese Analysen
              oder
              Profile personenbezogen sind, werden sie mit Kündigung der Nutzer
              gelöscht oder anonymisiert, sonst nach zwei Jahren ab
              Vertragsschluss.
              Im Übrigen werden die gesamtbetriebswirtschaftlichen Analysen und
              allgemeine Tendenzbestimmungen nach Möglichkeit anonym erstellt.
            </p>

            <p><strong>20. Kontaktaufnahme</strong></p>

            <p>Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular,
              E-Mail, Telefon
              oder via sozialer Medien) werden die Angaben des Nutzers zur
              Bearbeitung der
              Kontaktanfrage und deren Abwicklung gem. Art. 6 Abs. 1 lit. b. (im
              Rahmen
              vertraglicher-/vorvertraglicher Beziehungen), Art. 6 Abs. 1 lit.
              f. (andere
              Anfragen) DSGVO verarbeitet.. Die Angaben der Nutzer können in
              einem
              Customer-Relationship-Management System (&#8222;CRM System&#8220;)
              oder
              vergleichbarer Anfragenorganisation gespeichert werden.<br/>
              <br/> Wir löschen
              die Anfragen, sofern diese nicht mehr erforderlich sind. Wir
              überprüfen
              die Erforderlichkeit alle zwei Jahre; Ferner gelten die
              gesetzlichen
              Archivierungspflichten.</p>

            <p><strong>21. Hosting und E-Mail-Versand</strong></p>

            <p>Die von uns in Anspruch genommenen Hosting-Leistungen dienen der
              Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und
              Plattformdienstleistungen, Rechenkapazität, Speicherplatz und
              Datenbankdienste, E-Mail-Versand, Sicherheitsleistungen sowie
              technische
              Wartungsleistungen, die wir zum Zwecke des Betriebs dieses
              Onlineangebotes
              einsetzen.<br/> <br/> Hierbei verarbeiten wir, bzw. unser
              Hostinganbieter
              Bestandsdaten, Kontaktdaten, Inhaltsdaten, Vertragsdaten,
              Nutzungsdaten,
              Meta- und Kommunikationsdaten von Kunden, Interessenten und
              Besuchern
              dieses Onlineangebotes auf Grundlage unserer berechtigten
              Interessen an
              einer effizienten und sicheren Zurverfügungstellung dieses
              Onlineangebotes
              gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO (Abschluss
              Auftragsverarbeitungsvertrag).</p>

            <p><strong>22. Erhebung von Zugriffsdaten und Logfiles</strong></p>

            <p>Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer
              berechtigten
              Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO Daten über
              jeden Zugriff
              auf den Server, auf dem sich dieser Dienst befindet (sogenannte
              Serverlogfiles). Zu den Zugriffsdaten gehören Name der abgerufenen
              Webseite,
              Datei, Datum und Uhrzeit des Abrufs, übertragene Datenmenge,
              Meldung über
              erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem
              des
              Nutzers, Referrer URL (die zuvor besuchte Seite), IP-Adresse und
              der
              anfragende Provider.<br/> <br/> Logfile-Informationen werden aus
              Sicherheitsgründen (z.B. zur Aufklärung von Missbrauchs- oder
              Betrugshandlungen) für die Dauer von maximal 7 Tagen gespeichert
              und
              danach gelöscht. Daten, deren weitere Aufbewahrung zu
              Beweiszwecken
              erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen
              Vorfalls
              von der Löschung ausgenommen.</p>

            <p><strong>23. Google Analytics</strong></p>

            <p>Wir setzen Google Analytics, einen Webanalysedienst der Google
              Ireland
              Limited, Gordon House, Barrow Street, Dublin 4, Irland („Google“)
              ein.
              Google verwendet Cookies. Die durch das Cookie erzeugten
              Informationen über
              Benutzung des Onlineangebotes durch die Nutzer werden in der Regel
              an einen
              Server von Google in den USA übertragen und dort gespeichert.<br/>
              <br/> Google
              wird diese Informationen in unserem Auftrag benutzen, um die
              Nutzung
              unseres Onlineangebotes durch die Nutzer auszuwerten, um Reports
              über die
              Aktivitäten innerhalb dieses Onlineangebotes zusammenzustellen und
              um
              weitere, mit der Nutzung dieses Onlineangebotes und der
              Internetnutzung
              verbundene Dienstleistungen, uns gegenüber zu erbringen. Dabei
              können aus
              den verarbeiteten Daten pseudonyme Nutzungsprofile der Nutzer
              erstellt
              werden.<br/> <br/> Wir setzen Google Analytics nur mit aktivierter
              IP-Anonymisierung ein. Das bedeutet, die IP-Adresse der Nutzer
              wird von
              Google innerhalb von Mitgliedstaaten der Europäischen Union oder
              in
              anderen Vertragsstaaten des Abkommens über den Europäischen
              Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird die volle
              IP-Adresse
              an einen Server von Google in den USA übertragen und dort
              gekürzt.<br/> <br/> Die von dem Browser des Nutzers übermittelte
              IP-Adresse
              wird nicht mit anderen Daten von Google zusammengeführt. Die
              Nutzer
              können die Speicherung der Cookies durch eine entsprechende
              Einstellung ihrer Browser-Software verhindern; die Nutzer können
              darüber hinaus die Erfassung der durch das Cookie erzeugten und
              auf
              ihre Nutzung des Onlineangebotes bezogenen Daten an Google sowie
              die
              Verarbeitung dieser Daten durch Google verhindern, indem sie das
              unter
              folgendem Link verfügbare Browser-Plugin herunterladen und
              installieren: <a
                  href="http://tools.google.com/dlpage/gaoptout?hl=de"
                  target="_blank"
                  rel="noreferrer noopener">http://tools.google.com/dlpage/gaoptout?hl=de</a>.<br/>
              <br/> Sofern
              wir die Nutzer um eine Einwilligung bitten (z.B. im Rahmen einer
              Cookie-Einwilligung), ist die Rechtsgrundlage dieser Verarbeitung
              Art. 6 Abs. 1 lit. a. DSGVO. Ansonsten werden die
              personenbezogenen
              Daten der Nutzer auf Grundlage unserer berechtigten Interessen
              (d.h.
              Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb
              unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO)
              verarbeitet.<br/> <br/> Soweit Daten in den USA verarbeitet
              werden,
              weisen wir daraufhin, dass Google unter dem
              Privacy-Shield-Abkommen zertifiziert ist und hierdurch zusichert,
              das europäische Datenschutzrecht einzuhalten (<a
                  href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
                  target="_blank"
                  rel="noreferrer noopener">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</a>).<br/>
              <br/> Weitere
              Informationen zur Datennutzung durch Google, Einstellungs- und
              Widerspruchsmöglichkeiten, erfahren Sie in der
              Datenschutzerklärung von Google (<a
                  href="https://policies.google.com/privacy" target="_blank"
                  rel="noreferrer noopener">https://policies.google.com/privacy</a>)
              sowie in den Einstellungen für die Darstellung von
              Werbeeinblendungen durch Google <a
                  href="https://adssettings.google.com/authenticated"
                  target="_blank"
                  rel="noreferrer noopener">(https://adssettings.google.com/authenticated</a>).<br/>
              <br/> Die
              personenbezogenen Daten der Nutzer werden nach 14 Monaten
              gelöscht oder anonymisiert.</p>

            <p><strong>24. Google Universal Analytics</strong></p>

            <p>Wir setzen Google Analytics in der Ausgestaltung als „<a
                href="https://support.google.com/analytics/answer/2790010?hl=de&amp;ref_topic=6010376"
                target="_blank"
                rel="noreferrer noopener">Universal-Analytics</a>“ ein.
              „Universal Analytics“ bezeichnet ein Verfahren von Google
              Analytics, bei dem
              die Nutzeranalyse auf Grundlage einer pseudonymen Nutzer-ID
              erfolgt und
              damit ein pseudonymes Profil des Nutzers mit Informationen aus der
              Nutzung
              verschiedener Geräten erstellt wird (sog.
              „Cross-Device-Tracking“).</p>

            <p><strong>25. Zielgruppenbildung mit Google Analytics</strong></p>

            <p>Wir setzen Google Analytics ein, um die durch innerhalb von
              Werbediensten
              Googles und seiner Partner geschalteten Anzeigen, nur solchen
              Nutzern
              anzuzeigen, die auch ein Interesse an unserem Onlineangebot
              gezeigt haben
              oder die bestimmte Merkmale (z.B. Interessen an bestimmten Themen
              oder
              Produkten, die anhand der besuchten Webseiten bestimmt werden)
              aufweisen,
              die wir an Google übermitteln (sog. „Remarketing-“, bzw.
              „Google-Analytics-Audiences“). Mit Hilfe der Remarketing Audiences
              möchten
              wir auch sicherstellen, dass unsere Anzeigen dem potentiellen
              Interesse der
              Nutzer entsprechen.</p>

            <p><strong>26. Google AdWords und Conversion-Messung</strong></p>

            <p>Wir nutzen das Onlinemarketingverfahren
              Google &#8222;AdWords&#8220;, um
              Anzeigen im Google-Werbe-Netzwerk zu platzieren (z.B., in
              Suchergebnissen,
              in Videos, auf Webseiten, etc.), damit sie Nutzern angezeigt
              werden, die ein
              mutmaßliches Interesse an den Anzeigen haben. Dies erlaubt uns
              Anzeigen für
              und innerhalb unseres Onlineangebotes gezielter anzuzeigen, um
              Nutzern nur
              Anzeigen zu präsentieren, die potentiell deren Interessen
              entsprechen. Falls
              einem Nutzer z.B. Anzeigen für Produkte angezeigt werden, für die
              er sich
              auf anderen Onlineangeboten interessiert hat, spricht man hierbei
              vom
              „Remarketing“. Zu diesen Zwecken wird bei Aufruf unserer und
              anderer
              Webseiten, auf denen das Google-Werbe-Netzwerk aktiv ist,
              unmittelbar durch
              Google ein Code von Google ausgeführt und es werden sog.
              (Re)marketing-Tags
              (unsichtbare Grafiken oder Code, auch als &#8222;Web
              Beacons&#8220; bezeichnet) in die Webseite eingebunden. Mit deren
              Hilfe wird
              auf dem Gerät der Nutzer ein individuelles Cookie, d.h. eine
              kleine Datei
              abgespeichert (statt Cookies können auch vergleichbare
              Technologien
              verwendet werden). In dieser Datei wird vermerkt, welche Webseiten
              der
              Nutzer aufgesucht, für welche Inhalte er sich interessiert und
              welche
              Angebote der Nutzer geklickt hat, ferner technische Informationen
              zum
              Browser und Betriebssystem, verweisende Webseiten, Besuchszeit
              sowie weitere
              Angaben zur Nutzung des Onlineangebotes.<br/> <br/> Ferner
              erhalten wir ein
              individuelles „Conversion-Cookie“. Die mit Hilfe des Cookies
              eingeholten
              Informationen dienen Google dazu, Conversion-Statistiken für uns
              zu
              erstellen. Wir erfahren jedoch nur die anonyme Gesamtanzahl der
              Nutzer,
              die auf unsere Anzeige geklickt haben und zu einer mit einem
              Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden.
              Wir
              erhalten jedoch keine Informationen, mit denen sich Nutzer
              persönlich
              identifizieren lassen.<br/> <br/> Die Daten der Nutzer werden im
              Rahmen des
              Google-Werbe-Netzwerks pseudonym verarbeitet. D.h. Google
              speichert und
              verarbeitet z.B. nicht den Namen oder E-Mailadresse der Nutzer,
              sondern
              verarbeitet die relevanten Daten cookie-bezogen innerhalb
              pseudonymer
              Nutzerprofile. D.h. aus der Sicht von Google werden die Anzeigen
              nicht
              für eine konkret identifizierte Person verwaltet und angezeigt,
              sondern
              für den Cookie-Inhaber, unabhängig davon wer dieser Cookie-Inhaber
              ist.
              Dies gilt nicht, wenn ein Nutzer Google ausdrücklich erlaubt hat,
              die
              Daten ohne diese Pseudonymisierung zu verarbeiten. Die über die
              Nutzer
              gesammelten Informationen werden an Google übermittelt und auf
              Googles
              Servern in den USA gespeichert.<br/> <br/> Sofern wir die Nutzer
              um eine
              Einwilligung bitten (z.B. im Rahmen einer Cookie-Einwilligung),
              ist
              die Rechtsgrundlage dieser Verarbeitung Art. 6 Abs. 1 lit. a.
              DSGVO.
              Ansonsten werden die personenbezogenen Daten der Nutzer auf
              Grundlage
              unserer berechtigten Interessen (d.h. Interesse an der Analyse,
              Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes
              im
              Sinne des Art. 6 Abs. 1 lit. f. DSGVO) verarbeitet.<br/>
              <br/> Soweit
              Daten in den USA verarbeitet werden, weisen wir daraufhin, dass
              Google unter dem Privacy-Shield-Abkommen zertifiziert ist und
              hierdurch zusichert, das europäische Datenschutzrecht einzuhalten
              (<a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
                  target="_blank"
                  rel="noreferrer noopener">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</a>).<br/>
              <br/> Weitere
              Informationen zur Datennutzung durch Google, Einstellungs- und
              Widerspruchsmöglichkeiten, erfahren Sie in der
              Datenschutzerklärung von Google (<a
                  href="https://policies.google.com/technologies/ads"
                  target="_blank"
                  rel="noreferrer noopener">https://policies.google.com/technologies/ads</a>)
              sowie in den Einstellungen für die Darstellung von
              Werbeeinblendungen durch Google <a
                  href="https://adssettings.google.com/authenticated"
                  target="_blank"
                  rel="noreferrer noopener">(https://adssettings.google.com/authenticated</a>).
            </p>

            <p><strong>28. Facebook-Pixel, Custom Audiences und
              Facebook-Conversion</strong></p>

            <p>Innerhalb unseres Onlineangebotes wird das
              sog. &#8222;Facebook-Pixel&#8220; des sozialen Netzwerkes
              Facebook, welches
              von der Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal
              Harbour,
              Dublin 2, Irland betrieben wird (&#8222;Facebook&#8220;),
              eingesetzt.<br/> <br/> Mit Hilfe des Facebook-Pixels ist es
              Facebook zum einen
              möglich, die Besucher unseres Onlineangebotes als Zielgruppe für
              die
              Darstellung von Anzeigen (sog. &#8222;Facebook-Ads&#8220;) zu
              bestimmen.
              Dementsprechend setzen wir das Facebook-Pixel ein, um die durch
              uns
              geschalteten Facebook-Ads nur solchen Facebook-Nutzern anzuzeigen,
              die
              auch ein Interesse an unserem Onlineangebot gezeigt haben oder die
              bestimmte Merkmale (z.B. Interessen an bestimmten Themen oder
              Produkten,
              die anhand der besuchten Webseiten bestimmt werden) aufweisen, die
              wir an
              Facebook übermitteln (sog. „Custom Audiences“). Mit Hilfe des
              Facebook-Pixels möchten wir auch sicherstellen, dass unsere
              Facebook-Ads
              dem potentiellen Interesse der Nutzer entsprechen und nicht
              belästigend
              wirken. Mit Hilfe des Facebook-Pixels können wir ferner die
              Wirksamkeit
              der Facebook-Werbeanzeigen für statistische und
              Marktforschungszwecke
              nachvollziehen, in dem wir sehen ob Nutzer nachdem Klick auf eine
              Facebook-Werbeanzeige auf unsere Website weitergeleitet wurden
              (sog.
              „Conversion“).<br/> <br/> Die Verarbeitung der Daten durch
              Facebook erfolgt im Rahmen von Facebooks
              Datenverwendungsrichtlinie. Dementsprechend
              generelle Hinweise zur Darstellung von Facebook-Ads, in der
              Datenverwendungsrichtlinie von Facebook: <a
                  href="https://www.facebook.com/policy" target="_blank"
                  rel="noreferrer noopener">https://www.facebook.com/policy</a>.
              Spezielle Informationen und Details zum Facebook-Pixel und seiner
              Funktionsweise erhalten Sie im Hilfebereich von Facebook: <a
                  href="https://www.facebook.com/business/help/651294705016616"
                  target="_blank"
                  rel="noreferrer noopener">https://www.facebook.com/business/help/651294705016616</a>.<br/>
              <br/> Sofern
              wir die Nutzer um eine Einwilligung bitten (z.B. im Rahmen einer
              Cookie-Einwilligung), ist die Rechtsgrundlage dieser Verarbeitung
              Art.
              6 Abs. 1 lit. a. DSGVO. Ansonsten werden die personenbezogenen
              Daten
              der Nutzer auf Grundlage unserer berechtigten Interessen (d.h.
              Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb
              unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO)
              verarbeitet.<br/> <br/> Facebook ist unter dem
              Privacy-Shield-Abkommen
              zertifiziert und sichert hierdurch zu, das europäische
              Datenschutzrecht einzuhalten (<a
                  href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active"
                  target="_blank"
                  rel="noreferrer noopener">https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active</a>).<br/>
              <br/> Sie
              können der Erfassung durch den Facebook-Pixel und Verwendung Ihrer
              Daten zur Darstellung von Facebook-Ads widersprechen. Um
              einzustellen, welche Arten von Werbeanzeigen Ihnen innerhalb von
              Facebook angezeigt werden, können Sie die von Facebook
              eingerichtete Seite aufrufen und dort die Hinweise zu den
              Einstellungen nutzungsbasierter Werbung befolgen: <a
                  href="https://www.facebook.com/settings?tab=ads"
                  target="_blank"
                  rel="noreferrer noopener">https://www.facebook.com/settings?tab=ads</a>.
              Die Einstellungen erfolgen plattformunabhängig, d.h. sie werden
              für alle Geräte, wie Desktopcomputer oder mobile Geräte
              übernommen.<br/> <br/> Sie können dem Einsatz von Cookies, die der
              Reichweitenmessung und Werbezwecken dienen, ferner über die
              Deaktivierungsseite der Netzwerkwerbeinitiative (<a
                  href="http://optout.networkadvertising.org/" target="_blank"
                  rel="noreferrer noopener">http://optout.networkadvertising.org/</a>)
              und zusätzlich die US-amerikanische Webseite (<a
                  href="http://www.aboutads.info/choices" target="_blank"
                  rel="noreferrer noopener">http://www.aboutads.info/choices</a>)
              oder
              die europäische Webseite (<a
                  href="http://www.youronlinechoices.com/uk/your-ad-choices/"
                  target="_blank"
                  rel="noreferrer noopener">http://www.youronlinechoices.com/uk/your-ad-choices/</a>)
              widersprechen.</p>

            <p><strong>29. Onlinepräsenzen in sozialen Medien</strong></p>

            <p>Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und
              Plattformen, um mit den dort aktiven Kunden, Interessenten und
              Nutzern
              kommunizieren und sie dort über unsere Leistungen informieren zu
              können.<br/> <br/> Wir
              weisen darauf hin, dass dabei Daten der Nutzer außerhalb des
              Raumes der
              Europäischen Union verarbeitet werden können. Hierdurch können
              sich für
              die Nutzer Risiken ergeben, weil so z.B. die Durchsetzung der
              Rechte der
              Nutzer erschwert werden könnte. Im Hinblick auf US-Anbieter die
              unter dem
              Privacy-Shield zertifiziert sind, weisen wir darauf hin, dass sie
              sich
              damit verpflichten, die Datenschutzstandards der EU
              einzuhalten.<br/> <br/> Ferner
              werden die Daten der Nutzer im Regelfall für Marktforschungs- und
              Werbezwecke verarbeitet. So können z.B. aus dem Nutzungsverhalten
              und
              sich daraus ergebenden Interessen der Nutzer Nutzungsprofile
              erstellt
              werden. Die Nutzungsprofile können wiederum verwendet werden, um
              z.B.
              Werbeanzeigen innerhalb und außerhalb der Plattformen zu schalten,
              die
              mutmaßlich den Interessen der Nutzer entsprechen. Zu diesen
              Zwecken
              werden im Regelfall Cookies auf den Rechnern der Nutzer
              gespeichert, in
              denen das Nutzungsverhalten und die Interessen der Nutzer
              gespeichert
              werden. Ferner können in den Nutzungsprofilen auch Daten
              unabhängig der
              von den Nutzern verwendeten Geräte gespeichert werden
              (insbesondere wenn
              die Nutzer Mitglieder der jeweiligen Plattformen sind und bei
              diesen
              eingeloggt sind).<br/> <br/> Die Verarbeitung der
              personenbezogenen Daten
              der Nutzer erfolgt auf Grundlage unserer berechtigten Interessen
              an
              einer effektiven Information der Nutzer und Kommunikation mit den
              Nutzern gem. Art. 6 Abs. 1 lit. f. DSGVO. Falls die Nutzer von den
              jeweiligen Anbietern der Plattformen um eine Einwilligung in die
              vorbeschriebene Datenverarbeitung gebeten werden, ist die
              Rechtsgrundlage der Verarbeitung Art. 6 Abs. 1 lit. a., Art. 7
              DSGVO.<br/> <br/> Für eine detaillierte Darstellung der jeweiligen
              Verarbeitungen und der Widerspruchsmöglichkeiten (Opt-Out),
              verweisen wir auf die nachfolgend verlinkten Angaben der
              Anbieter.<br/> <br/> Auch im Fall von Auskunftsanfragen und der
              Geltendmachung von Nutzerrechten, weisen wir darauf hin, dass
              diese am effektivsten bei den Anbietern geltend gemacht werden
              können. Nur die Anbieter haben jeweils Zugriff auf die Daten der
              Nutzer und können direkt entsprechende Maßnahmen ergreifen und
              Auskünfte geben. Sollten Sie dennoch Hilfe benötigen, dann können
              Sie sich an uns wenden.<br/> <br/> &#8211; Facebook, -Seiten,
              -Gruppen, (Facebook Ireland Ltd., 4 Grand Canal Square, Grand
              Canal Harbour, Dublin 2, Irland) auf Grundlage einer <a
                  href="https://www.facebook.com/legal/terms/page_controller_addendum"
                  target="_blank" rel="noreferrer noopener">Vereinbarung über
                gemeinsame Verarbeitung personenbezogener
                Daten</a> &#8211; Datenschutzerklärung: <a
                  href="https://www.facebook.com/about/privacy/"
                  target="_blank"
                  rel="noreferrer noopener">https://www.facebook.com/about/privacy/</a>,
              speziell für Seiten: <a
                  href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
                  target="_blank"
                  rel="noreferrer noopener">https://www.facebook.com/legal/terms/<br/>information_about_page_insights_data</a>,
              Opt-Out: <a
                  href="https://www.facebook.com/settings?tab=ads"
                  target="_blank"
                  rel="noreferrer noopener">https://www.facebook.com/settings?tab=ads</a> und
              <a href="http://www.youronlinechoices.com/" target="_blank"
                 rel="noreferrer noopener">http://www.youronlinechoices.com</a>,
              Privacy Shield: <a
                  href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active"
                  target="_blank"
                  rel="noreferrer noopener">https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active</a>.<br/>
              <br/> &#8211; Google/
              YouTube (Google Ireland Limited, Gordon House, Barrow Street,
              Dublin 4, Irland) – Datenschutzerklärung: <a
                  href="https://policies.google.com/privacy" target="_blank"
                  rel="noreferrer noopener">https://policies.google.com/privacy</a>,
              Opt-Out: <a
                  href="https://adssettings.google.com/authenticated"
                  target="_blank"
                  rel="noreferrer noopener">https://adssettings.google.com/authenticated</a>,
              Privacy Shield: <a
                  href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
                  target="_blank"
                  rel="noreferrer noopener">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</a>.<br/>
              <br/> &#8211; Instagram
              (Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025,
              USA) – Datenschutzerklärung/ Opt-Out: <a
                  href="http://instagram.com/about/legal/privacy/"
                  target="_blank"
                  rel="noreferrer noopener">http://instagram.com/about/legal/privacy/</a>.<br/>
              <br/> &#8211; Twitter
              (Twitter Inc., 1355 Market Street, Suite 900, San
              Francisco, CA 94103,
              USA) &#8211; Datenschutzerklärung: <a
                  href="https://twitter.com/de/privacy" target="_blank"
                  rel="noreferrer noopener">https://twitter.com/de/privacy</a>,
              Opt-Out: <a
                  href="https://twitter.com/personalization"
                  target="_blank"
                  rel="noreferrer noopener">https://twitter.com/personalization</a>,
              Privacy Shield: <a
                  href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active"
                  target="_blank"
                  rel="noreferrer noopener">https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active</a>.<br/>
              <br/> &#8211; Pinterest
              (Pinterest Inc., 635 High Street, Palo Alto, CA, 94301,
              USA) – Datenschutzerklärung/ Opt-Out: <a
                  href="https://about.pinterest.com/de/privacy-policy"
                  target="_blank"
                  rel="noreferrer noopener">https://about.pinterest.com/de/privacy-policy</a>.<br/>
              <br/> &#8211; LinkedIn
              (LinkedIn Ireland Unlimited Company Wilton Place,
              Dublin 2, Irland) &#8211; Datenschutzerklärung <a
                  href="https://www.linkedin.com/legal/privacy-policy"
                  target="_blank"
                  rel="noreferrer noopener">https://www.linkedin.com/legal/privacy-policy</a> ,
              Opt-Out: <a
                  href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
                  target="_blank"
                  rel="noreferrer noopener">https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out</a>,
              Privacy Shield: <a
                  href="https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&amp;status=Active"
                  target="_blank"
                  rel="noreferrer noopener">https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&amp;status=Active</a>.<br/>
              <br/> &#8211; Xing
              (XING AG, Dammtorstraße 29-32, 20354 Hamburg,
              Deutschland) &#8211; Datenschutzerklärung/
              Opt-Out: <a
                  href="https://privacy.xing.com/de/datenschutzerklaerung"
                  target="_blank"
                  rel="noreferrer noopener">https://privacy.xing.com/de/datenschutzerklaerung</a>.<br/>
              <br/> &#8211; Wakalet
              (Wakelet Limited, 76 Quay Street, Manchester, M3
              4PR, United Kingdom) &#8211; Datenschutzerklärung/
              Opt-Out: <a
                  href="https://wakelet.com/privacy.html"
                  target="_blank"
                  rel="noreferrer noopener">https://wakelet.com/privacy.html</a>.<br/>
              <br/> &#8211; Soundcloud
              (SoundCloud Limited, Rheinsberger Str. 76/77,
              10115 Berlin,
              Deutschland) &#8211; Datenschutzerklärung/
              Opt-Out: <a
                  href="https://soundcloud.com/pages/privacy"
                  target="_blank"
                  rel="noreferrer noopener">https://soundcloud.com/pages/privacy</a>.
            </p>

            <p><strong>30. Einbindung von Diensten und Inhalten Dritter</strong>
            </p>

            <p>Wir setzen innerhalb unseres Onlineangebotes auf Grundlage
              unserer
              berechtigten Interessen (d.h. Interesse an der Analyse,
              Optimierung und
              wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art.
              6 Abs. 1
              lit. f. DSGVO) Inhalts- oder Serviceangebote von Drittanbietern
              ein, um
              deren Inhalte und Services, wie z.B. Videos oder Schriftarten
              einzubinden
              (nachfolgend einheitlich bezeichnet als “Inhalte”).<br/>
              <br/> Dies setzt immer
              voraus, dass die Drittanbieter dieser Inhalte, die IP-Adresse der
              Nutzer
              wahrnehmen, da sie ohne die IP-Adresse die Inhalte nicht an deren
              Browser
              senden könnten. Die IP-Adresse ist damit für die Darstellung
              dieser
              Inhalte erforderlich. Wir bemühen uns nur solche Inhalte zu
              verwenden,
              deren jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung
              der
              Inhalte verwenden. Drittanbieter können ferner so genannte
              Pixel-Tags
              (unsichtbare Grafiken, auch als &#8222;Web
              Beacons&#8220; bezeichnet) für
              statistische oder Marketingzwecke verwenden. Durch
              die &#8222;Pixel-Tags&#8220; können Informationen, wie der
              Besucherverkehr
              auf den Seiten dieser Website ausgewertet werden. Die pseudonymen
              Informationen können ferner in Cookies auf dem Gerät der Nutzer
              gespeichert werden und unter anderem technische Informationen zum
              Browser
              und Betriebssystem, verweisende Webseiten, Besuchszeit sowie
              weitere
              Angaben zur Nutzung unseres Onlineangebotes enthalten, als auch
              mit
              solchen Informationen aus anderen Quellen verbunden werden.</p>

            <p><strong>31. Verwendung von Facebook Social Plugins</strong></p>

            <p>Wir nutzen auf Grundlage unserer berechtigten Interessen (d.h.
              Interesse an
              der Analyse, Optimierung und wirtschaftlichem Betrieb unseres
              Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Social
              Plugins
              (&#8222;Plugins&#8220;) des sozialen Netzwerkes facebook.com,
              welches von
              der Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal
              Harbour, Dublin
              2, Irland betrieben wird (&#8222;Facebook&#8220;).<br/> Hierzu
              können z.B.
              Inhalte wie Bilder, Videos oder Texte und Schaltflächen gehören,
              mit denen
              Nutzer Inhalte dieses Onlineangebotes innerhalb von Facebook
              teilen
              können. Die Liste und das Aussehen der Facebook Social Plugins
              kann hier
              eingesehen werden: <a
                  href="https://developers.facebook.com/docs/plugins/"
                  target="_blank"
                  rel="noreferrer noopener">https://developers.facebook.com/docs/plugins/</a>.<br/>
              <br/> Facebook
              ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet
              hierdurch
              eine Garantie, das europäische Datenschutzrecht einzuhalten (<a
                  href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active"
                  target="_blank"
                  rel="noreferrer noopener">https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active</a>).<br/>
              <br/> Wenn
              ein Nutzer eine Funktion dieses Onlineangebotes aufruft, die ein
              solches Plugin enthält, baut sein Gerät eine direkte Verbindung
              mit
              den Servern von Facebook auf. Der Inhalt des Plugins wird von
              Facebook
              direkt an das Gerät des Nutzers übermittelt und von diesem in das
              Onlineangebot eingebunden. Dabei können aus den verarbeiteten
              Daten
              Nutzungsprofile der Nutzer erstellt werden. Wir haben daher keinen
              Einfluss auf den Umfang der Daten, die Facebook mit Hilfe dieses
              Plugins erhebt und informiert die Nutzer daher entsprechend
              unserem
              Kenntnisstand.<br/> <br/> Durch die Einbindung der Plugins erhält
              Facebook
              die Information, dass ein Nutzer die entsprechende Seite des
              Onlineangebotes aufgerufen hat. Ist der Nutzer bei Facebook
              eingeloggt, kann Facebook den Besuch seinem Facebook-Konto
              zuordnen.
              Wenn Nutzer mit den Plugins interagieren, zum Beispiel den Like
              Button betätigen oder einen Kommentar abgeben, wird die
              entsprechende Information von Ihrem Gerät direkt an Facebook
              übermittelt und dort gespeichert. Falls ein Nutzer kein Mitglied
              von
              Facebook ist, besteht trotzdem die Möglichkeit, dass Facebook
              seine
              IP-Adresse in Erfahrung bringt und speichert. Laut Facebook wird
              in
              Deutschland nur eine anonymisierte IP-Adresse gespeichert.<br/>
              <br/> Zweck
              und Umfang der Datenerhebung und die weitere Verarbeitung und
              Nutzung der Daten durch Facebook sowie die diesbezüglichen Rechte
              und Einstellungsmöglichkeiten zum Schutz der Privatsphäre der
              Nutzer, können diese den Datenschutzhinweisen von Facebook
              entnehmen: <a href="https://www.facebook.com/about/privacy/"
                            target="_blank"
                            rel="noreferrer noopener">https://www.facebook.com/about/privacy/</a>.<br/>
              <br/> Wenn
              ein Nutzer Facebookmitglied ist und nicht möchte, dass Facebook
              über dieses Onlineangebot Daten über ihn sammelt und mit seinen
              bei Facebook gespeicherten Mitgliedsdaten verknüpft, muss er
              sich vor der Nutzung unseres Onlineangebotes bei Facebook
              ausloggen und seine Cookies löschen. Weitere Einstellungen und
              Widersprüche zur Nutzung von Daten für Werbezwecke, sind
              innerhalb der Facebook-Profileinstellungen möglich: <a
                  href="https://www.facebook.com/settings?tab=ads"
                  target="_blank"
                  rel="noreferrer noopener">https://www.facebook.com/settings?tab=ads</a> oder
              über die US-amerikanische Seite <a
                  href="http://www.aboutads.info/choices/" target="_blank"
                  rel="noreferrer noopener">http://www.aboutads.info/choices/</a> oder
              die EU-Seite <a href="http://www.youronlinechoices.com/"
                              target="_blank"
                              rel="noreferrer noopener">http://www.youronlinechoices.com/</a>.
              Die Einstellungen erfolgen plattformunabhängig, d.h. sie werden
              für alle Geräte, wie Desktopcomputer oder mobile Geräte
              übernommen.</p>

            <p><strong>32. Instagram</strong></p>

            <p>Innerhalb unseres Onlineangebotes können Funktionen und Inhalte
              des
              Dienstes Instagram, angeboten durch die Instagram Inc., 1601
              Willow Road,
              Menlo Park, CA, 94025, USA, eingebunden werden. Hierzu können z.B.
              Inhalte
              wie Bilder, Videos oder Texte und Schaltflächen gehören, mit denen
              Nutzer
              Inhalte dieses Onlineangebotes innerhalb von Instagram teilen
              können. Sofern
              die Nutzer Mitglieder der Plattform Instagram sind, kann Instagram
              den
              Aufruf der o.g. Inhalte und Funktionen den dortigen Profilen der
              Nutzer
              zuordnen. Datenschutzerklärung von Instagram: <a
                  href="http://instagram.com/about/legal/privacy/"
                  target="_blank"
                  rel="noreferrer noopener">http://instagram.com/about/legal/privacy/</a>.
            </p>

            <p><strong>33. Pinterest</strong></p>

            <p>Innerhalb unseres Onlineangebotes können Funktionen und Inhalte
              des
              Dienstes Pinterest, angeboten durch die Pinterest Inc., 635 High
              Street,
              Palo Alto, CA, 94301, USA, eingebunden werden. Hierzu können z.B.
              Inhalte
              wie Bilder, Videos oder Texte und Schaltflächen gehören, mit denen
              Nutzer
              Inhalte dieses Onlineangebotes innerhalb von Pinterest teilen
              können. Sofern
              die Nutzer Mitglieder der Plattform Pinterest sind, kann Pinterest
              den
              Aufruf der o.g. Inhalte und Funktionen den dortigen Profilen der
              Nutzer
              zuordnen. Datenschutzerklärung von Pinterest: <a
                  href="https://about.pinterest.com/de/privacy-policy"
                  target="_blank"
                  rel="noreferrer noopener">https://about.pinterest.com/de/privacy-policy</a>.
            </p>

            <p><strong>34. Google+</strong></p>

            <p>Innerhalb unseres Onlineangebotes können Funktionen und Inhalte
              der
              Plattform Google+, angeboten durch die Google Ireland Limited,
              Gordon House,
              Barrow Street, Dublin 4, Irland („Google“), eingebunden werden.
              Hierzu
              können z.B. Inhalte wie Bilder, Videos oder Texte und
              Schaltflächen gehören,
              mit denen Nutzer Inhalte dieses Onlineangebotes innerhalb von
              Google teilen
              können. Sofern die Nutzer Mitglieder der Plattform Google+ sind,
              kann Google
              den Aufruf der o.g. Inhalte und Funktionen den dortigen Profilen
              der Nutzer
              zuordnen.<br/> <br/> Google ist unter dem Privacy-Shield-Abkommen
              zertifiziert
              und bietet hierdurch eine Garantie, das europäische
              Datenschutzrecht
              einzuhalten (<a
                  href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
                  target="_blank"
                  rel="noreferrer noopener">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</a>).
              Weitere Informationen zur Datennutzung durch Google, Einstellungs-
              und
              Widerspruchsmöglichkeiten, erfahren Sie in der
              Datenschutzerklärung von
              Google (<a href="https://policies.google.com/technologies/ads"
                         target="_blank"
                         rel="noreferrer noopener">https://policies.google.com/technologies/ads</a>)
              sowie in den Einstellungen für die Darstellung von
              Werbeeinblendungen
              durch Google <a
                  href="https://adssettings.google.com/authenticated"
                  target="_blank"
                  rel="noreferrer noopener">(https://adssettings.google.com/authenticated</a>).
            </p>

            <p>Diese Datenschutzerklärung wurde durch den
              Datenschutzerklärungs-Generator
              der DGD Deutsche Gesellschaft für Datenschutz GmbH, die als
              <a
                  href="https://dg-datenschutz.de/datenschutz-dienstleistungen/externer-datenschutzbeauftragter/"
                  target="_blank" rel="noreferrer noopener">Externer
                Datenschutzbeauftragter Oberpfalz</a> tätig ist, in
              Kooperation mit
              der RC GmbH, die <a href="http://remarketing.company/"
                                  target="_blank"
                                  rel="noreferrer noopener">gebrauchte
                Computer</a> wiederverwertet und der Kanzlei <a
                  href="https://www.wbs-law.de/" target="_blank"
                  rel="noreferrer noopener">WILDE BEUGER SOLMECKE |
                Rechtsanwälte</a> erstellt.</p>


            <h4><br/> Kontakt</h4>

            <p><br/> Jürgen Kilchert</p>

            <p>E-Mail: info@holz-und-glasvisionen.de</p>
          </div>
        </div>

      </div>

  )
}
export default ImpressumText