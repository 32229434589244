import React from 'react'
import {Link, NavLink} from 'react-router-dom'
import {footerLinks} from '../data'

const Footer = () => {
  return (
      // <footer className='text-black footer'>
      //   <div className="container flex flex-wrap">
      //     <article className="flex-1">
      //       <Link to="/" className='logo'><h1>Holz & Glas Visionen</h1></Link>
      //       {/*<div className="socials">*/}
      //       {/*    <a href="https://www.linkedin.com/in/anita-ikediashi-a61668188" target='_blank'><FiLinkedin/></a>*/}
      //       {/*    <a href="https://twitter.com/Anita_ikediashi" target='_blank'><FiTwitter/></a>*/}
      //       {/*    <a href="https://github.com/AnitaIkediashi" target='_blank'><RiGithubLine/></a>*/}
      //       {/*</div>*/}
      //     </article>
      //
      //     <article className="mt-4 md:mt-0">
      //       <h4 className="text-xl">Quick Links</h4>
      //       <ul className="m-2">
      //         {
      //           footerLinks.map(({name, path}, index) => {
      //             return (
      //                 <li key={index}>
      //                   <NavLink to={path}>{name}</NavLink>
      //                 </li>
      //             )
      //           })
      //         }
      //       </ul>
      //     </article>
      //   </div>
      //
      //   <div className={"text-center"}>
      //     <small className="m-1">
      //       Copyright &copy; {new Date().getFullYear()} designed by Holz & Glas Visionen
      //     </small>
      //   </div>
      //
      // </footer>

  <footer className="bg-gray-800 text-white py-4">
    <div className="container mx-auto">
      <div className="flex justify-between flex-col lg:flex-row items-center ">
        <div className="flex">
          <ul className="m-2">
            {
              footerLinks.map(({name, path}, index) => {
                return (
                    <li className="text-sm px-2 hover:underline" key={index}>
                      <NavLink to={path}>{name}</NavLink>
                    </li>
                )
              })
            }
          </ul>
        </div>
        <p className="text-sm text-center">Copyright &copy; {new Date().getFullYear()} designed by Holz & Glas Visionen</p>
      </div>
    </div>
  </footer>

  )
}

export default Footer