import React, {useEffect, useState} from 'react'
import products from "../../productMeta/productMeta.json"
import Product from "./Product";

const ProductGallery = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
      <>
        <div className="p-10 grid gap-3 grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3">
            {products.map((item) => {
              return <Product key={item.key} product={item} />
            })}

        </div>

      </>
  )
}

export default ProductGallery