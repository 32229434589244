import React from 'react'
import logo
  from "../images/glasVision/logo.jpg";
import {Container, Grid} from "@mui/material";

const MainHeader = () => {

  return (
      <>
        <section className="about-us">
          <Container>
            <h1 className="text-center text-5xl md:text-7xl p-6">
              Holz & Glas Visionen
            </h1>
            <Grid container spacing={2} columns={{ xs: 4, md: 12 }} className="pt-4">
              <Grid item xs={6} className="text-lg">
                <div className="">
                  <h3 className="text-4xl pb-4">Über uns</h3>
                  <p>Wir sind ein kleines Unternehmen aus dem Fichtelgebirge, das sich der Herstellung von einzigartigen und handgefertigten
                    Holzprodukten mit Epoxidharz verschrieben hat. Unsere Produkte zeichnen sich durch ihre Schönheit, Haltbarkeit und ihr modernes Design aus.</p>
                  <br/>
                  <p>Unser Anspruch ist es Sie zu jeder Jahreszeit und jedem Anlass zu verzaubern.</p>
                  <br/>
                  <p>Bei der Herstellung unserer Produkte legen wir größten Wert auf Handarbeit.
                    Jedes Stück ist einzigartig und von uns mit Leidenschaft und Präzision hergestellt.
                    Unsere Kunden schätzen die Individualität und den Charakter, der jedem einzelnen Produkt innewohnt.</p>
                </div>

              </Grid>

              <Grid item xs={6}>
                <div className="text-center">
                  <img src={logo} alt="about us"/>
                </div>

              </Grid>

            </Grid>
          </Container>
        </section>
      </>
  )
}

export default MainHeader