import React, {useEffect, useState} from 'react'
import MainHeader from '../../components/MainHeader'
import Contact from "../contact/Contact";
import About from "../about/About";
import ScrollButton from '../../components/ScrollButton'
import Shipping from "../../components/home/Shipping";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
      <>
          <MainHeader/>
          <About/>
          <Shipping />
          <Contact/>
          <ScrollButton/>
      </>
  )
}

export default Home