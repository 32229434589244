import React, {useEffect, useState} from 'react'
import {FaArrowCircleUp} from 'react-icons/fa';

const ScrollButton = () => {
  // The back-to-top button is hidden at the beginning
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  // This function will scroll the window to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
  };
  return (
      <button type="button" className="fixed right-6 md:right-1
      bottom-4
      h-8
      text-4xl
      z-1
      cursor-pointer
      text-white">
        <FaArrowCircleUp className="scrollTop" onClick={scrollToTop}
                         style={{display: showButton ? 'inline' : 'none'}} />
      </button>
  )
}

export default ScrollButton