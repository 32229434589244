import React from 'react'
import MainHeader from '../../components/MainHeader'
import './impressum.css'
import ImpressumText from "../../components/ImpressumText";

const Impressum = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
      <>
        <MainHeader/>
        <ImpressumText/>
      </>
  )
}

export default Impressum