import React from 'react'
import About1 from '../images/products/dezember/P1100432.jpg'
import {Container, Grid, Paper, styled} from "@mui/material";

const AboutUs = () => {

  return (
      <section className="about-us">
        <Container>
          <Grid container spacing={2} columns={{ xs: 4, md: 12 }}>
            <Grid item xs={6}>
              <div className="text-center">
                <img src={About1} alt="about us" className="w-96"/>
              </div>

            </Grid>
            <Grid item xs={6} className="text-lg">
              <div className="text-center">
                <h4 className="text-4xl pb-4">Was wir machen</h4>
                <p>Verarbeitung von heimischen Hölzern und Althölzern in teils einzigartigen Verfahren zu Deko für Garten und Heim.</p>
                <br/>
                <p>Ob mit Branding, geflammt, oder Epoxidhartz, Licht, Farben und Glitter</p>
                <br/>
                <p>Lassen Sie Ihre Wünsche und Ideen mit in das Produkt einfließen</p>
                <br/>

                <a href="article/epoxidharz"
                   className="bg-amber-700 text-white py-2 px-4 rounded-lg hover:bg-amber-600 text-center text-decoration-none">
                  Über Epoxidharz
                </a>


              </div>

            </Grid>
          </Grid>
        </Container>
      </section>
  )
}

export default AboutUs