import React from 'react'
import Card from '../pages/UI/Card'
import {BsGlobe, BsMailbox} from 'react-icons/bs'
import About1 from "../images/glasVision/10/Holzgeschenke.jpg";
import {
  Container,
  Grid,
  Link,
  Stack,
  Tooltip,
  tooltipClasses,
  Typography,
  TooltipProps, styled
} from "@mui/material";
import {Email, LanguageRounded, LocationOn} from "@mui/icons-material";
import Image from 'mui-image'


const ContactPage = () => {
  const email = "info@holz-und-glasvisionen.de";

  function copy() {
    navigator.clipboard.writeText(email)
    document.getElementById("custom-tooltip").style.display = "inline";
    document.execCommand("copy");
    setTimeout(function () {
      document.getElementById("custom-tooltip").style.display = "none";
    }, 1000);
  }

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
      <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  return (
      <section className="contact ">
        <Container>
          <Grid container spacing={{ xs: 2, md: 2}}>
            <Grid item xs={12} sm={6} >
              <Stack spacing={2} >

                <Grid container sx={{ minHeight: 150, textAlign:"center", alignItems:"center", backgroundColor: "gray",
                  "&:hover": { backgroundColor: "rgb(217 119 6)", cursor:"pointer" }}}
                      onClick={copy}>
                  <Grid item xs={1}>
                    <Typography color="white">
                      <Email fontSize="large" />
                    </Typography>
                  </Grid>
                  <Grid item xs={11}>
                    <Typography variant="h6" color="white" >
                      <LightTooltip title="click to copy">
                        <address>
                          {email}
                        </address>
                      </LightTooltip>
                    </Typography>
                  </Grid>
                </Grid>


                <Grid container sx={{ minHeight: 150, textAlign:"center", alignItems:"center", backgroundColor: "gray",
                  "&:hover": { backgroundColor: "rgb(217 119 6)", cursor:"pointer" }}}
                      onClick={() => { window.open('https://holz-und-glasvisionen.de') }}>
                  <Grid item xs={1}>
                    <Typography color="white">
                      <LanguageRounded fontSize="large"/>
                    </Typography>
                  </Grid>
                  <Grid item xs={11}>
                    <Typography variant="h6" color="white" >
                      <address>
                        holz-und-glasvisionen.de
                      </address>
                    </Typography>
                  </Grid>
                </Grid>

              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Image src={About1} alt="about us" />
            </Grid>

          </Grid>
        </Container>
      </section>
  )
}

export default ContactPage