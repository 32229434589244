import React from 'react'
import {Container, Grid} from "@mui/material";

const Shipping = () => {

  return (
      <>
        <section>
          <Container>
            <Grid container spacing={2} columns={{ xs: 4, md: 12 }} className="pt-4">
              <Grid item xs={6} className="text-lg">
                <div className="">
                  <h3 className="text-4xl pb-4">Versand</h3>
                  <p>Derzeit können wir keinen einheitlichen Versand anbieten, dies muss individuell je nach Bestellung vereinbart werden. Dazu nehmen wir Kontakt mit Ihnen auf.</p>
                  <br/>
                  <p>Wenn Sie nach Informationen über unsere Produkte suchen oder eine spezifische Anfrage haben, zögern Sie bitte nicht,
                    uns per E-Mail zu kontaktieren.</p>
                  <br/>
                  <p>Senden Sie uns einfach eine E-Mail an</p>
                  <p><a className="hover:bg-amber-400" href="mailto:info@holz-und-glasvisionen.de">info@holz-und-glasvisionen.de</a></p>
                  <p> und wir werden uns so schnell wie möglich bei Ihnen melden. Wir freuen uns auf Ihre Nachricht!</p>
                </div>

              </Grid>

            </Grid>
          </Container>
        </section>
      </>
  )
}

export default Shipping