import React from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Home from './pages/home/Home'
import Footer from './components/Footer'
import Impressum from "./pages/Rechtlich/Impressum";
import CookieDialog from "./components/CookieDialog";
import NotFound from "./components/NotFound";
import Products from "./pages/products/Products";
import NavBar from "./components/navbar/Navbar";
import Epoxidharz from "./components/article/Epoxidharz";

const App = () => {
  return (
      <BrowserRouter>
        <NavBar />
          <Routes>
            <Route path='*' element={<NotFound/>} />
            <Route index element={<Home/>}/>
            {/*<Route path='about' element={<About/>}/>*/}
            <Route path='impressum' element={<Impressum/>}/>
            {/*<Route path='contact' element={<Contact/>}/>*/}
            <Route path='products' element={<Products/>}/>
            <Route path='article/epoxidharz' element={<Epoxidharz />}/>
          </Routes>

        <Footer/>
        <CookieDialog/>
      </BrowserRouter>
  )
}

export default App