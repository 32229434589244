import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import logo from '../../images/glasVision/logo.jpg';

const NavBar = () => {
  const [stickyClass, setStickyClass] = useState('relative');

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);

    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;

      windowHeight > 100 ? setStickyClass('fixed top-0 left-0 z-50') : setStickyClass('');
    }
    console.log(stickyClass);
  };

  const [open, setOpen] = useState(false);

  return (
      <nav className={`bg-white w-full ${stickyClass}`}>
        <div className="flex items-center font-medium justify-around">
          <div className="z-50 p-2 md:w-auto w-full flex justify-between">
            {/*<img src={Logo} alt="logo" className="md:cursor-pointer h-9" />*/}
            <a href="/">
              <img src={logo} alt="logo" className="md:cursor-pointer w-16 md:h-24 md:w-28 "/>
            </a>
            <div className="text-3xl md:hidden z-10" onClick={() => setOpen(!open)}>
                <FontAwesomeIcon icon={faBars} />
            </div>
          </div>
          <ul className="md:flex hidden uppercase items-center gap-8">
            <li>
              <Link to="/" className="py-7 px-3 inline-block">
                Home
              </Link>
            </li>
            <li>
              <Link to="/products" className="py-7 px-3 inline-block">
                Produkte
              </Link>
            </li>
          </ul>
          {/*<div className="md:block hidden">*/}
          {/*  <Button />*/}
          {/*</div>*/}
          {/* Mobile nav */}
          <ul className={`md:hidden bg-white fixed w-full top-0 overflow-y-auto bottom-0 py-24 pl-4 duration-500 ${open ? "left-0" : "left-[-100%]"} z-10`}>
            <li>
              <Link to="/" className="py-7 px-3 inline-block" onClick={() => setOpen(false)}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/products" className="py-7 px-3 inline-block" onClick={() => setOpen(false)}>
                Produkte
              </Link>
            </li>
            {/*<div className="py-5">*/}
            {/*  <Button />*/}
            {/*</div>*/}
          </ul>
        </div>
      </nav>
  );
}

export default NavBar