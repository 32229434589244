import React, {useEffect, useState} from 'react'
import ProductGallery from "../../components/products/ProductsGallery";

const Products = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
      <>
        <div className="container mx-auto ">
          <ProductGallery />
        </div>
      </>
  )
}

export default Products