import React from 'react'
import ContactPage from '../../components/ContactPage'

const Contact = () => {
  return (
    <>
      <ContactPage/>
    </>
  )
}

export default Contact