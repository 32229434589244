import React, {useState} from "react";
import Modal from 'react-modal';


const customStyles = {
  content: {
    fixed: true,
    top: '55%',
    left: '50%',
    right: '50%',
    bottom: '50%',
    marginRight: '-20%',
    width: '50%',
    height: '80%',
    transform: 'translate(-50%, -50%)',
  },
};

const Products = ({ product }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const [zoom, setZoom] = useState(1);


  const handleImageClick = (item) => {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      // true for mobile device
    }else{
      setSelectedItem(item);
      setModalIsOpen(true);
    }
  }

  let picture = "";

  try {
    picture = require(`../../images/products/${product.path}`);
    product.picture = picture;
  } catch {

  }

  return (
      <>
        <div className="flex flex-col rounded shadow-lg">
            <img className="lg:h-96 object-contain cursor-pointer"
                src={picture}
                 alt={product.alt}
                 onClick={() => handleImageClick(product)} />
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">{product.title}</div>
              <p className="text-gray-700 text-base">
                {product.description}
              </p>
            </div>
            <div className="px-6 pt-4 pb-2">
              <p>
                {product.price}
              </p>
            </div>
        </div>

        <Modal isOpen={modalIsOpen}
               onRequestClose={() => setModalIsOpen(false)}
               style={customStyles}
               ariaHideApp={false}>
          <div className={"w-full h-[90%]"}>
            <div className="flex flex-row justify-between">
              <h1>{selectedItem.title}</h1>
              <button onClick={() => setModalIsOpen(false)} className="bg-amber-700 text-white py-2 px-4 rounded-lg hover:bg-amber-600 text-center text-decoration-none m-2">
                Schließen
              </button>
            </div>

            <img src={selectedItem.picture} alt={selectedItem.alt} className={"object-contain w-full h-full"} />
          </div>


        </Modal>
      </>
  )
};

export default Products;
