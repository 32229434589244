import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import {useCookies} from "react-cookie";
import ReactGA from "react-ga4";

const CookieDialog = () => {



  const [cookies, setCookie, removeCookie] = useCookies(["consent"]);

  if (cookies.consent) {
    initGa();
  }


  const [open, setOpen] = React.useState(
      cookies.consent === "" || cookies.consent === undefined);

  function handleCookie() {
    setCookie("consent", true, {
      path: "/", maxAge: 31536000
    });
    initGa();
    setOpen(false);
  }
  function initGa(){
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }

  const handleDeclineCookie = () => {
    for (let cookiesKey in cookies) {
      removeCookie(cookiesKey);
    }
    setOpen(false);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (

      <div>
        <Dialog
            fullScreen={fullScreen}
            open={open}
            aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <DialogContentText>
              Diese Seite nutzt Website-Tracking-Technologien von Dritten, um
              ihre Dienste anzubieten, stetig zu verbessern und Werbung
              entsprechend den Interessen der Nutzer anzuzeigen.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleCookie}>
              Accept
            </Button>
            <Button onClick={handleDeclineCookie}>
              Ablehnen
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default CookieDialog;